import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import React, { useState } from "react";
import Admin from "components/Layouts/Admin";
import MyDropzone from "components/MyDropzone/MyDropzone";
import ValidationErrors from "components/ValidationErrors";
import { Link, useNavigate } from "react-router-dom";
import { createBlog } from "api/blogs";
import { PUBLIC_URL } from "common/defines";
import { useNotification } from "contexts/NotificationContext";
import Editor from "components/Editor";

const Create = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const { notification } = useNotification();

    const [data, setData] = useState({
        title: "",
        thumbnail: null,
        details: "",
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = () => {
        setLoading(true);
        setErrors(null);

        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("details", data.details);
        if (data.thumbnail) {
            formData.append("thumbnail", data.thumbnail, data.thumbnail?.name);
        }

        createBlog(formData)
            .then((response) => {
                notification.success("Blog created successfully");
                navigate(PUBLIC_URL + "/blogs/");
            })
            .catch((_errors) => {
                notification.failed("Failed to create blog");
                setErrors(_errors.response.data);
            })
            .finally(() => setLoading(false));
    };

    const handleThumbnailDrop = (files) => {
        setData((prevState) => ({ ...prevState, thumbnail: files[0] }));
    };

    const handleThumbnailRemove = () =>
        setData((prevState) => ({ ...prevState, thumbnail: null }));

    const actions = (
        <Link to={"/blogs"}>
            <Button
                variant="contained"
                size="small"
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>
        </Link>
    );

    return (
        <Admin sectionTitle="Add blog" selected={"Blogs"} actions={actions}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <>
                        <TextField
                            type={"text"}
                            name="title"
                            label="Title"
                            onChange={handleChange}
                            sx={{
                                marginY: 2,
                                width: "100%",
                            }}
                        />

                        <Editor
                            placeholder={"Details"}
                            defaultValue={data.details}
                            onChange={(value) =>
                                setData((prev) => ({
                                    ...prev,
                                    details: value,
                                }))
                            }
                            className="ql-description"
                        />

                        <InputLabel sx={{ marginY: 2 }}> Thumbnail </InputLabel>
                        <MyDropzone
                            title="Drag thumbnail here"
                            subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                            maxFilesCount={1}
                            acceptableTypes={{
                                "image/png": [],
                                "image/jpeg": [],
                            }}
                            defaultFiles={[data.thumbnail]}
                            handleDrop={handleThumbnailDrop}
                            handleRemove={handleThumbnailRemove}
                        />

                        <Button
                            type="button"
                            size="small"
                            variant="contained"
                            disabled={loading}
                            sx={{ display: "block", marginY: 2 }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={errors} />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Create;
