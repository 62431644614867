import React, { useState } from "react";
import { Snackbar as MuiSnackbar, IconButton, Alert } from "@mui/material";
import { Close } from "@mui/icons-material";

const Snackbar = () => {
    const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setIsOpenSnackbar(false)}
        >
            <Close fontSize="small" />
        </IconButton>
    );

    return (
        <MuiSnackbar
            open={isOpenSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={() => setIsOpenSnackbar(false)}
            action={action}
        >
            <Alert
                onClose={() => setIsOpenSnackbar(false)}
                severity={"success"}
                sx={{ width: "100%" }}
            >
                Message Here
            </Alert>
        </MuiSnackbar>
    );
};

export default Snackbar;
