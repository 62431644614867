import React from 'react';
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    IconButton,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from 'common/defines';

const AdminCard = ({ admin, onDelete }) => {
    return (
        <Card variant='outlined' sx={{ minWidth: '18rem;' }}>
            <CardContent sx={{ justifyContent: 'center' }}>
                <Avatar
                    sx={{
                        backgroundColor: '#38B6FF',
                        margin: '0 auto',
                    }}
                    aria-label='recipe'>
                    {admin.name[0]}
                </Avatar>
                <Typography
                    variant='h6'
                    sx={{ textAlign: 'center', paddingY: '1rem' }}>
                    {admin.name}
                </Typography>
                <Typography sx={{ textAlign: 'center' }}>
                    {admin.email}
                </Typography>
            </CardContent>
            <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
                <IconButton aria-label='Edit admin'>
                    <Link to={PUBLIC_URL + '/admins/' + admin.id + '/edit'}>
                        <EditIcon />
                    </Link>
                </IconButton>
                <IconButton aria-label='Delete admin' onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default AdminCard;
