import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useMemo, useState } from "react";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const initState = {
    open: false,
    message: null,
    variant: "success",
};

export const NotificationProvider = ({ children }) => {
    const [state, setState] = useState(initState);

    const handleClose = () => setState(initState);

    const notification = useMemo(
        () => ({
            success: (message) =>
                setState({ open: true, variant: "success", message }),
            failed: (message) =>
                setState({ open: true, variant: "failed", message }),
        }),
        []
    );

    const Notification = () => (
        <Snackbar
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            open={state.open}
            autoHideDuration={2000}
            onClose={handleClose}
        >
            <Alert
                variant="filled"
                severity={state.variant === "success" ? "success" : "error"}
                elevation={6}
            >
                {state.message}
            </Alert>
        </Snackbar>
    );

    const value = {
        notification,
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
            <Notification />
        </NotificationContext.Provider>
    );
};
