import {
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";

const AnalyticsCard = ({ title, data }) => (
    <Card>
        <CardContent>
            <Typography variant="h6">{title}</Typography>
            <List>
                {data.map((item) => (
                    <ListItem
                        sx={{ paddingTop: 0 }}
                        secondaryAction={item.value}
                    >
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
        </CardContent>
    </Card>
);

export default AnalyticsCard;
