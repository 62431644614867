import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Admin from "components/Layouts/Admin";
import ValidationErrors from "components/ValidationErrors";
import { useNavigate } from "react-router-dom";
import { useNotification } from "contexts/NotificationContext";
import { createOrder, getOrderProducts } from "api/orders";
import uuid from "react-uuid";
import { PUBLIC_URL } from "common/defines";

const Create = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [products, setProducts] = useState([]);
    const [orderData, setOrderData] = useState({
        products: [
            {
                product_id: uuid(),
                title: null,
                quantity: 0,
                price: 0,
                total_amount: 0,
            },
        ],
        customer: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            street_address: "",
            postcode: "",
            city: "",
            province: "",
            country: "canada",
        },
        payment: {
            status: "DUE",
            subtotal: 0,
            grand_total: 0,
            hst: 0,
            shipping_charge: 0,
        },
    });

    const { notification } = useNotification();

    const navigate = useNavigate();

    const productSubtotal = useMemo(() => {
        let subtotal = 0;
        orderData.products.forEach(
            (_product) => (subtotal += _product.total_amount)
        );
        return subtotal;
    }, [orderData.products]);

    const calculateSubtotal = (productsData) => {
        let subtotal = 0;
        productsData.forEach((_product) => (subtotal += _product.total_amount));
        return subtotal;
    };

    const calculateHst = (hst, subtotal) => (hst / 100) * subtotal;

    const calculateGrandTotal = useCallback(
        (subtotal, hst, shippingCharge) =>
            subtotal + calculateHst(hst, subtotal) + shippingCharge,
        []
    );

    const productGrandTotal = useMemo(() => {
        const _subtotal = calculateSubtotal(orderData.products);

        return calculateGrandTotal(
            _subtotal,
            orderData.payment.hst,
            orderData.payment.shipping_charge
        );
    }, [
        orderData.products,
        orderData.payment.hst,
        orderData.payment.shipping_charge,
        calculateGrandTotal,
    ]);

    const handleProductChange = (_id, _value) => {
        const selectedProduct = products.find((_p) => _p.id === _value);
        setOrderData((prevData) => ({
            ...prevData,
            products: prevData.products.map((_product) =>
                _product.product_id === _id
                    ? {
                          ..._product,
                          product_id: _value,
                          quantity: 1,
                          price: selectedProduct.price,
                          total_amount: selectedProduct.price,
                      }
                    : _product
            ),
        }));
    };

    const handleAddProduct = () =>
        setOrderData((prevData) => ({
            ...prevData,
            products: [
                ...prevData.products,
                {
                    product_id: uuid(),
                    title: null,
                    quantity: 0,
                    price: 0,
                    total_amount: 0,
                },
            ],
        }));

    const handleRemoveProduct = (_id) =>
        setOrderData((prevData) => ({
            ...prevData,
            products: prevData.products.filter(
                (_product) => _product.product_id !== _id
            ),
        }));

    const handleQuantityChange = (_id, _value) => {
        setOrderData((prevData) => ({
            ...prevData,
            products: prevData.products.map((_p) =>
                _p.product_id === _id
                    ? {
                          ..._p,
                          quantity: parseInt(_value, 10),
                          total_amount: _p.price * _value,
                      }
                    : _p
            ),
        }));
    };

    const handleAmountChange = (_id, _value) => {
        setOrderData((prevData) => ({
            ...prevData,
            products: prevData.products.map((_p) =>
                _p.product_id === _id
                    ? {
                          ..._p,
                          price: _value,
                          total_amount: _p.quantity * _value,
                      }
                    : _p
            ),
        }));
    };

    const handleCustomerChange = (_name, _value) =>
        setOrderData((prevData) => ({
            ...prevData,
            customer: { ...prevData.customer, [_name]: _value },
        }));

    const handlePaymentChange = (_name, _value) =>
        setOrderData((prevData) => ({
            ...prevData,
            payment: { ...prevData.payment, [_name]: _value },
        }));

    const handleChangeShippingCharge = (_value) => {
        setOrderData((prevData) => ({
            ...prevData,
            payment: {
                ...prevData.payment,
                shipping_charge: _value,
                grand_total: calculateGrandTotal(
                    prevData.payment.subtotal,
                    prevData.payment.hst,
                    _value
                ),
            },
        }));
    };

    const handleChangeHst = (_value) => {
        setOrderData((prevData) => ({
            ...prevData,
            payment: {
                ...prevData.payment,
                hst: _value,
                grand_total: calculateGrandTotal(
                    prevData.payment.subtotal,
                    _value,
                    prevData.payment.shipping_charge
                ),
            },
        }));
    };

    const handleSubmit = () => {
        const data = {
            ...orderData,
            payment: {
                ...orderData.payment,
                subtotal: productSubtotal,
                hst: calculateHst(orderData.payment.hst, productSubtotal),
                grand_total: productGrandTotal,
            },
        };
        createOrder(data)
            .then((response) => {
                notification.success("Order created successfully");
                navigate(PUBLIC_URL + "/orders/" + response.data.id);
            })
            .catch((errors) => {
                setErrors(errors.response.data);
                notification.failed("Failed to create order");
            });
    };

    const actions = (
        <Button variant="contained" size="small" onClick={handleSubmit}>
            Submit
        </Button>
    );

    const fetchProducts = useCallback(() => {
        getOrderProducts()
            .then((res) => {
                setProducts(res.data.data);
            })
            .catch((_errors) => {
                console.log("failed to fetch products");
            });
    }, []);

    let productSelectOptions = useMemo(() => {
        return products
            ? products.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                      {option.sku} - {option.title}
                  </MenuItem>
              ))
            : null;
    }, [products]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    console.log("render");
    return (
        <Admin sectionTitle="Add Order" selected={"Orders"} actions={actions}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <Card>
                        <CardHeader title="Products" />
                        <CardContent>
                            {orderData.products.map((_product) => (
                                <Grid
                                    container
                                    sx={{
                                        borderBottom: "1px solid #ccc",
                                        padding: "1rem 0",
                                    }}
                                >
                                    <Grid item xs={12} md={5}>
                                        <InputLabel>Product*</InputLabel>
                                        <Select
                                            value={_product.product_id}
                                            required
                                            fullWidth
                                            onChange={(event) =>
                                                handleProductChange(
                                                    _product.product_id,
                                                    event.target.value
                                                )
                                            }
                                        >
                                            {productSelectOptions}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <InputLabel>Quantity*</InputLabel>
                                        <TextField
                                            type="number"
                                            required
                                            fullWidth
                                            value={_product.quantity}
                                            onChange={(event) =>
                                                handleQuantityChange(
                                                    _product.product_id,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <InputLabel>Amount*</InputLabel>
                                        <TextField
                                            type="number"
                                            required
                                            fullWidth
                                            value={_product.price}
                                            onChange={(event) =>
                                                handleAmountChange(
                                                    _product.product_id,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <InputLabel>Total</InputLabel>
                                        <TextField
                                            type="number"
                                            disabled
                                            fullWidth
                                            required
                                            value={_product.total_amount}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={1}
                                        sx={{ margin: "auto 0" }}
                                    >
                                        <IconButton
                                            sx={{ marginTop: "1rem" }}
                                            onClick={() =>
                                                handleRemoveProduct(
                                                    _product.product_id
                                                )
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button
                                variant="contained"
                                sx={{ marginTop: 2 }}
                                onClick={handleAddProduct}
                            >
                                Add
                            </Button>
                        </CardContent>
                    </Card>
                    <Card sx={{ marginTop: "1rem" }}>
                        <CardHeader title="Customer Info" />
                        <CardContent>
                            <TextField
                                label="First Name"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "first_name",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                            />
                            <TextField
                                label="Last Name"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "last_name",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                label="Email"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "email",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                label="Phone Number"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "phone",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                label="Street Address"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "street_address",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                label="Post Code"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "postcode",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                label="City"
                                onChange={(event) =>
                                    handleCustomerChange(
                                        "city",
                                        event.target.value
                                    )
                                }
                                fullWidth
                                required
                                sx={{ marginTop: "1rem" }}
                            />
                            <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                                <InputLabel>Province</InputLabel>
                                <Select
                                    value={orderData.customer.province}
                                    label="Province"
                                    onChange={(event) =>
                                        handleCustomerChange(
                                            "province",
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem value="Alberta">Alberta</MenuItem>
                                    <MenuItem value="BritishColumbia">
                                        British Columbia
                                    </MenuItem>
                                    <MenuItem value="Manitoba">
                                        Manitoba
                                    </MenuItem>
                                    <MenuItem value="NewBrunswick">
                                        New Brunswick
                                    </MenuItem>
                                    <MenuItem value="NewfoundlandAndLabrador">
                                        Newfoundland and Labrador
                                    </MenuItem>
                                    <MenuItem value="NorthwestTerritories">
                                        Northwest Territories
                                    </MenuItem>
                                    <MenuItem value="NovaScotia">
                                        Nova Scotia
                                    </MenuItem>
                                    <MenuItem value="Nunavut">Nunavut</MenuItem>
                                    <MenuItem value="Ontario">Ontario</MenuItem>
                                    <MenuItem value="PrinceEdwardIsland">
                                        Prince Edward Island
                                    </MenuItem>
                                    <MenuItem value="Quebec">Quebec</MenuItem>
                                    <MenuItem value="Saskatchewan">
                                        Saskatchewan
                                    </MenuItem>
                                    <MenuItem value="YukonTerritory">
                                        Yukon Territory
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                                <InputLabel>Country</InputLabel>
                                <Select
                                    value={orderData.customer.country}
                                    label="Country"
                                    onChange={(event) =>
                                        handleCustomerChange(
                                            "country",
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem value="canada">Canada</MenuItem>
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                    <Card sx={{ marginTop: "1rem" }}>
                        <CardHeader title="Pyament Info" />
                        <CardContent>
                            <TextField
                                label="Subtotal"
                                fullWidth
                                required
                                disabled
                                value={productSubtotal}
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                type="number"
                                label="HST(%)"
                                fullWidth
                                required
                                value={orderData.payment.hst}
                                onChange={(event) =>
                                    handleChangeHst(
                                        parseFloat(event.target.value)
                                    )
                                }
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                type="number"
                                label="Shipping Charge"
                                fullWidth
                                required
                                value={orderData.payment.shipping_charge}
                                onChange={(event) =>
                                    handleChangeShippingCharge(
                                        parseFloat(event.target.value)
                                    )
                                }
                                sx={{ marginTop: "1rem" }}
                            />
                            <TextField
                                label="Grand Total"
                                fullWidth
                                required
                                disabled
                                value={productGrandTotal}
                                sx={{ marginTop: "1rem" }}
                            />
                            <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                                <InputLabel>Payment Status</InputLabel>
                                <Select
                                    value={orderData.payment.status}
                                    label="Payment Status"
                                    onChange={(event) =>
                                        handlePaymentChange(
                                            "status",
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem value="COMPLETED">
                                        Completed
                                    </MenuItem>
                                    <MenuItem value="DUE">Due</MenuItem>
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={errors} />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Create;
