import React, { useState } from "react";
import Guest from "components/Layouts/Guest";
import {
    Typography,
    Alert,
    TextField,
    Button,
    Card,
    CardContent,
} from "@mui/material";
import { useAuth } from "contexts/AuthContext";

export default function ForgotPassword({ status }) {
    const { forgotPassword, loading } = useAuth();

    const [data, setData] = useState({
        email: "",
    });

    const submit = () => {
        forgotPassword(data);
    };

    return (
        <Guest>
            <Card variant="outlined" sx={{ borderRadius: "2rem" }}>
                <CardContent>
                    <Typography>
                        Forgot your password? No problem. Just let us know your
                        email address and we will email you a password reset
                        link that will allow you to choose a new one.
                    </Typography>

                    {status && <Alert severity="success">{status}</Alert>}

                    <form>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            name="email"
                            value={data.email}
                            autoComplete="email"
                            onChange={(e) => setData({ email: e.target.value })}
                            fullWidth
                            sx={{ marginY: 2 }}
                        />

                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            disabled={loading}
                            sx={{ display: "block", width: "100%", marginY: 2 }}
                            onClick={submit}
                        >
                            Email Password Reset Link
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Guest>
    );
}
