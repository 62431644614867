import axiosService from 'common/axiosService';
import { API_URL } from 'common/defines';

export const getAdmins = async () =>
    await axiosService.get(API_URL + '/admins');

export const getAdmin = async (id) =>
    await axiosService.get(API_URL + '/admins/' + id);

export const createAdmin = async (data) =>
    await axiosService.post(API_URL + '/admins', data);

export const updateAdmin = async (id, data) =>
    await axiosService.post(API_URL + '/admins/' + id, data);

export const deleteAdmin = async (id) =>
    await axiosService.delete(API_URL + '/admins/' + id);
