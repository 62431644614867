import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "./MyDropzone.styled";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import uuid from "react-uuid";
import { Delete as DeleteIcon } from "@mui/icons-material";

const MyDropzone = ({
    title = "",
    subTitle = "",
    maxFilesCount = 10,
    acceptableTypes = null,
    defaultFiles = [],
    handleDrop,
    handleRemove,
}) => {
    const [files, setFiles] = useState([]);

    const handleOnDrop = useCallback(
        (acceptedFiles) => handleDrop(acceptedFiles),
        [handleDrop]
    );

    const { getRootProps, fileRejections, getInputProps, isDragActive } =
        useDropzone({
            onDrop: handleOnDrop,
            accept: acceptableTypes,
            maxFiles: maxFilesCount,
        });

    useEffect(() => {
        const _files = defaultFiles
            ?.map((defaultFile) => {
                if (defaultFile) {
                    return {
                        id: uuid(),
                        name: defaultFile.name,
                        preview: URL.createObjectURL(defaultFile),
                    };
                }

                return null;
            })
            .filter((_item) => _item);

        setFiles(_files);
    }, [defaultFiles]);

    return (
        <div
            style={{
                boxShadow: "0 0 3px #ccc",
                padding: "0.5rem",
                borderRadius: "0.5rem",
            }}
        >
            <Box {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop files here ...</p>
                ) : (
                    <div>
                        <p>{title}</p>
                        <small>({subTitle})</small>
                    </div>
                )}

                {fileRejections.map(({ file, errors }) => (
                    <Alert severity="error" key={uuid()} sx={{ marginY: 1 }}>
                        <AlertTitle>{file.path}</AlertTitle>
                        <ul>
                            {errors.map((e) => (
                                <li key={uuid()}>{e.message}</li>
                            ))}
                        </ul>
                    </Alert>
                ))}
            </Box>
            <div style={{ padding: "1rem 1rem 0 1rem" }}>
                {files.map((file) => (
                    <div
                        style={{
                            display: "inline-flex",
                            borderRadius: 2,
                            border: "1px solid #eaeaea",
                            marginBottom: 8,
                            marginRight: 8,
                            width: 150,
                            height: 150,
                            padding: 4,
                            boxSizing: "border-box",
                        }}
                        key={file.name}
                    >
                        <div
                            style={{
                                display: "flex",
                                minWidth: 0,
                                overflow: "hidden",
                                position: "relative",
                            }}
                        >
                            <img
                                alt={file.name}
                                src={file.preview}
                                style={{
                                    display: "block",
                                    width: "auto",
                                    height: "100%",
                                }}
                                onLoad={() => {
                                    URL.revokeObjectURL(file.preview);
                                }}
                            />
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    bottom: 0,
                                }}
                                onClick={() => handleRemove(file.name)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MyDropzone;
