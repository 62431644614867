import { useEffect, useState } from "react";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import Dialog from "components/Dialog/Dialog";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import Admin from "components/Layouts/Admin";
import DataTable from "components/DataTable/DataTable";
import { Link } from "react-router-dom";
import { PUBLIC_URL } from "common/defines";
import { useCategory } from "contexts/CategoryContext";
import FetchDataWrapper from "components/FetchDataWrapper";

const Index = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const {
        categories,
        loading,
        deleteLoading,
        errors,
        getCategories,
        deleteCategory,
    } = useCategory();
    const theme = useTheme();

    const actions = (
        <Link to={PUBLIC_URL + "/categories/create"}>
            <Button variant="contained" size="small" startIcon={<AddIcon />}>
                Add Category
            </Button>
        </Link>
    );

    // prepare categories for datatable
    const headers = ["ID", "Name", "Image", "Products", "Actions"];

    const handleDelete = () => {
        deleteCategory(dialogContent.id);
        handleDialog();
    };

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <Admin
            sectionTitle="Categories"
            selected={"Categories"}
            actions={actions}
        >
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={categories}
                renderData={(data) => (
                    <DataTable
                        headers={headers}
                        rows={data.map((category) => [
                            category.id,
                            category.name,
                            <img
                                src={category.thumbnail}
                                alt=""
                                style={{ height: "50px" }}
                            />,
                            <div>{category.product_count}</div>,
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.2rem",
                                }}
                            >
                                <Link
                                    to={
                                        PUBLIC_URL +
                                        "/categories/" +
                                        category.id +
                                        "/edit"
                                    }
                                    style={{
                                        color: theme.palette.action.active,
                                    }}
                                >
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                                <IconButton
                                    onClick={() => handleDialog(category)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>,
                        ])}
                    />
                )}
            />
            <Dialog
                title="Delete Category"
                subTitle="Are you sure you want to delete this category?"
                processing={deleteLoading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
