import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import { getSettings, updateSettings } from "api/settings";
import { useNotification } from "contexts/NotificationContext";
import FetchDataWrapper from "components/FetchDataWrapper";
import ValidationErrors from "components/ValidationErrors";

const Settings = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateErrors, setUpdateErrors] = useState(null);
    const [settings, setSettings] = useState();
    const { notification } = useNotification();

    const handleChange = (name, value) =>
        setSettings((prev) => ({ ...prev, [name]: value }));

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            setUpdateLoading(true);
            setUpdateErrors(null);

            updateSettings(settings)
                .then(() => {
                    notification.success("Settings updated successfully");
                })
                .catch((_errors) => {
                    setUpdateErrors(_errors.response.data);
                })
                .finally(() => setUpdateLoading(false));
        },
        [notification, settings]
    );

    const handleGetSettings = useCallback(() => {
        setLoading(true);
        getSettings()
            .then((response) => setSettings(response.data.data))
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        handleGetSettings();
    }, [handleGetSettings]);

    return (
        <Admin sectionTitle="Settings" selected={"Settings"}>
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={settings}
                renderData={(settings) => (
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <Card
                                    sx={{
                                        mb: 2,
                                        width: "100%",
                                    }}
                                >
                                    <Typography p={2}>
                                        <strong>PayPal</strong>
                                    </Typography>
                                    <Divider />
                                    <CardContent>
                                        <InputLabel>Client ID</InputLabel>

                                        <TextField
                                            type="text"
                                            name="paypal_key"
                                            variant="outlined"
                                            size="small"
                                            defaultValue={settings?.paypal_key}
                                            onChange={(e) =>
                                                handleChange(
                                                    "paypal_key",
                                                    e.target.value
                                                )
                                            }
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                            }}
                                            multiline
                                            rows={4}
                                        />
                                        <InputLabel>Currency</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                size="small"
                                                defaultValue={
                                                    settings?.paypal_currency
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        "paypal_currency",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem
                                                    value={
                                                        settings?.paypal_currency
                                                    }
                                                >
                                                    CAD
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <InputLabel>Country</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                size="small"
                                                defaultValue={
                                                    settings?.paypal_country
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        "paypal_country",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={"en_CA"}>
                                                    Canada
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </CardContent>
                                </Card>

                                <Card
                                    sx={{
                                        mb: 2,
                                        width: "100%",
                                    }}
                                >
                                    <Typography p={2}>
                                        <strong>Order</strong>
                                    </Typography>
                                    <Divider />
                                    <CardContent>
                                        <InputLabel>HST(%)</InputLabel>
                                        <TextField
                                            type="text"
                                            name="paypal_key"
                                            variant="outlined"
                                            size="small"
                                            defaultValue={settings?.tax}
                                            onChange={(e) =>
                                                handleChange(
                                                    "tax",
                                                    e.target.value
                                                )
                                            }
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                            }}
                                        />
                                        <InputLabel>
                                            Shipping charge($)
                                        </InputLabel>
                                        <TextField
                                            type="text"
                                            name="paypal_key"
                                            variant="outlined"
                                            size="small"
                                            defaultValue={
                                                settings?.shipping_charge
                                            }
                                            onChange={(e) =>
                                                handleChange(
                                                    "shipping_charge",
                                                    e.target.value
                                                )
                                            }
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                            }}
                                        />
                                    </CardContent>
                                </Card>

                                <Card
                                    sx={{
                                        mb: 2,
                                        width: "100%",
                                    }}
                                >
                                    <Typography p={2}>
                                        <strong>Authentication</strong>
                                    </Typography>
                                    <Divider />
                                    <CardContent>
                                        <InputLabel>Google key</InputLabel>
                                        <TextField
                                            type="text"
                                            name="paypal_key"
                                            variant="outlined"
                                            size="small"
                                            defaultValue={settings?.google_key}
                                            onChange={(e) =>
                                                handleChange(
                                                    "google_key",
                                                    e.target.value
                                                )
                                            }
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                            }}
                                            multiline
                                            rows={4}
                                        />

                                        <InputLabel>Facebook key</InputLabel>
                                        <TextField
                                            type="text"
                                            name="paypal_key"
                                            variant="outlined"
                                            size="small"
                                            defaultValue={
                                                settings?.facebook_key
                                            }
                                            onChange={(e) =>
                                                handleChange(
                                                    "facebook_key",
                                                    e.target.value
                                                )
                                            }
                                            sx={{
                                                mb: 2,
                                                width: "100%",
                                            }}
                                            multiline
                                            rows={4}
                                        />
                                    </CardContent>
                                </Card>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={updateLoading}
                                >
                                    Save changes
                                </Button>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                            <ValidationErrors errors={updateErrors} />
                        </Grid>
                    </Grid>
                )}
            />
        </Admin>
    );
};

export default Settings;
