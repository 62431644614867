import React from "react";
import ApplicationLogo from "components/ApplicationLogo";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function Guest({ children }) {
    return (
        <Box
            paddingX={2}
            maxWidth="sm"
            mx="auto"
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Link to={"/"}>
                <ApplicationLogo />
            </Link>
            <Box sx={{ width: "100%" }}>{children}</Box>
        </Box>
    );
}
