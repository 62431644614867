import { Button, Grid, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import Admin from "components/Layouts/Admin";
import ValidationErrors from "components/ValidationErrors";
import MyDropzone from "components/MyDropzone/MyDropzone";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useCategory } from "contexts/CategoryContext";
import { PUBLIC_URL } from "common/defines";

const Create = () => {
    const { createLoading, createErrors, createCategory } = useCategory();

    const [data, setData] = useState({
        name: "",
        thumbnail: null,
    });

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("name", data.name);
        if (data.thumbnail) {
            formData.append("thumbnail", data.thumbnail, data.thumbnail?.name);
        }

        createCategory(formData);
    };

    const handleThumbnailDrop = (files) => {
        setData((prevState) => ({ ...prevState, thumbnail: files[0] }));
    };

    const handleThumbnailRemove = () => {
        setData((prevState) => ({ ...prevState, thumbnail: null }));
    };

    const actions = (
        <Link to={PUBLIC_URL + "/categories"}>
            <Button
                variant="contained"
                size="small"
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>
        </Link>
    );

    return (
        <Admin
            sectionTitle="Add Category"
            selected={"Categories"}
            actions={actions}
        >
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <>
                        <TextField
                            name="name"
                            label="Category Title"
                            onChange={handleChange}
                            sx={{
                                marginY: 2,
                                width: "100%",
                            }}
                        />

                        <InputLabel sx={{ marginY: 2 }}>
                            {" "}
                            Category Image{" "}
                        </InputLabel>
                        <MyDropzone
                            title="Drag your image here"
                            subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                            maxFilesCount={1}
                            acceptableTypes={{
                                "image/png": [],
                                "image/jpeg": [],
                            }}
                            defaultFiles={[data.thumbnail]}
                            handleDrop={handleThumbnailDrop}
                            handleRemove={handleThumbnailRemove}
                        />

                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            disabled={createLoading}
                            sx={{ display: "block", marginY: 2 }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={createErrors} />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Create;
