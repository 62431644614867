import axiosService from "common/axiosService";
import { API_URL } from "common/defines";

export const getCoupons = async () => {
    return await axiosService.get(API_URL + "/coupons");
};

export const addCoupons = async (data) => {
    return await axiosService.post(API_URL + "/coupons", data);
};

export const deleteCoupons = async (id) => {
    return await axiosService.delete(API_URL + "/coupons/" + id);
};
