import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Link as MuiLink,
    Box,
    Typography,
    Drawer,
    IconButton,
} from "@mui/material";
import {
    Dashboard as DashboardIcon,
    Logout as LogoutIcon,
    Inventory as InventoryIcon,
    Category as CategoryIcon,
    AdminPanelSettings,
} from "@mui/icons-material";
import logo from "assets/images/logo.png";
import uuid from "react-uuid";
import { Link } from "react-router-dom";
import Snackbar from "components/Snackbar";
import { useAuth } from "contexts/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArticleIcon from "@mui/icons-material/Article";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import SettingsIcon from "@mui/icons-material/Settings";
import DiscountIcon from "@mui/icons-material/Discount";
import AdUnitsIcon from "@mui/icons-material/AdUnits";

export default function Admin({
    sectionTitle = "",
    selected = null,
    actions = null,
    children,
}) {
    const { admin, logout } = useAuth();
    const [openDrawer, setOpenDrawer] = useState(false);

    const menuItems = [
        {
            title: "Dashboard",
            icon: <DashboardIcon />,
            link: "/",
        },
        {
            title: "Orders",
            icon: <ShoppingCartIcon />,
            link: "/orders",
        },
        {
            title: "Products",
            icon: <InventoryIcon />,
            link: "/products",
        },
        {
            title: "Coupons",
            icon: <DiscountIcon />,
            link: "/coupons",
        },
        {
            title: "Categories",
            icon: <CategoryIcon />,
            link: "/categories",
        },
        {
            title: "Customers",
            icon: <PeopleAltIcon />,
            link: "/customers",
        },
        {
            title: "Admins",
            icon: <AdminPanelSettings />,
            link: "/admins",
        },
        {
            title: "Blogs",
            icon: <ArticleIcon />,
            link: "/blogs",
        },
        {
            title: "Homepage Slider",
            icon: <WebStoriesIcon />,
            link: "/ui/homepage-slider",
        },
        {
            title: "Popup",
            icon: <AdUnitsIcon />,
            link: "/ui/popup",
        },
        {
            title: "Settings",
            icon: <SettingsIcon />,
            link: "/settings",
        },
    ];

    const menuItemsEl = (
        <List>
            {menuItems.map((item) => (
                <ListItem
                    key={uuid()}
                    disablePadding
                    selected={selected === item.title}
                >
                    <ListItemButton component={Link} to={item.link}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </ListItem>
            ))}
            <ListItem key={uuid()} disablePadding>
                <ListItemButton onClick={logout}>
                    <ListItemIcon>
                        {" "}
                        <LogoutIcon />{" "}
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItemButton>
            </ListItem>
        </List>
    );

    return (
        <>
            <AppBar
                position="sticky"
                color="default"
                sx={{
                    boxShadow: "none",
                    backdropFilter: "saturate(180%) blur(20px)",
                    backgroundColor: "rgba(255,255,255,0.72)",
                    borderBottom: "1px solid rgba(0,0,0,0.16)",
                }}
            >
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <IconButton
                        sx={{
                            display: {
                                xs: "block",
                                lg: "none",
                            },
                        }}
                        onClick={() => setOpenDrawer((prev) => !prev)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <MuiLink as={Link} to={"/"}>
                        <img
                            className="logo_sm"
                            src={logo}
                            style={{ width: "100px", padding: "0.2rem 0" }}
                            alt="YourPaints"
                        />
                    </MuiLink>
                    <Typography>Welcome, {admin.name}</Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={2} marginBottom={2} sx={{ padding: 2 }}>
                <Grid
                    item
                    xs={12}
                    lg={2}
                    sx={{
                        display: {
                            xs: "none",
                            lg: "block",
                        },
                        borderRight: {
                            xs: "none",
                            lg: "1px solid rgba(0,0,0,0.16)",
                        },
                    }}
                >
                    {menuItemsEl}
                </Grid>
                <Grid item xs={12} lg={10}>
                    <Grid
                        container
                        marginY={3}
                        sx={{
                            borderBottom: "1px solid #e0e0e0",
                            padding: "0.5rem",
                            marginY: "0.5rem",
                        }}
                    >
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h6">
                                {" "}
                                {sectionTitle}{" "}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={8} sx={{ textAlign: "right" }}>
                            {actions}
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            borderRadius: "0.5rem",
                            padding: {
                                xs: "0rem",
                                lg: "1rem",
                            },
                        }}
                    >
                        {children}
                    </Box>
                </Grid>
            </Grid>
            <Snackbar />
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Box sx={{ padding: "1rem" }}>{menuItemsEl}</Box>
            </Drawer>
        </>
    );
}
