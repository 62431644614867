import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import Admin from "components/Layouts/Admin";
import MyDropzone from "components/MyDropzone/MyDropzone";
import { useNavigate } from "react-router-dom";
import { useCategory } from "contexts/CategoryContext";
import { createProduct } from "api/products";
import { PUBLIC_URL } from "common/defines";
import uuid from "react-uuid";
import TabPanel from "components/TabPanel";
import ClearIcon from "@mui/icons-material/Clear";
import { useNotification } from "contexts/NotificationContext";
import ValidationErrors from "components/ValidationErrors";
import Editor from "components/Editor";

const Create = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { categories } = useCategory();
    const [tab, setTab] = useState(0);
    const { notification } = useNotification();

    const [data, setData] = useState({
        title: "",
        short_description: null,
        description: null,
        price: 0.0,
        discount: 0.0,
        stock: 0,
        published: true,
        thumbnail: null,
        images: [],
        category_id: null,
        specifications: [],
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSpecificationChange = (specificationId, event) => {
        setData((prev) => ({
            ...prev,
            specifications: prev.specifications.map((_item) =>
                _item.id === specificationId
                    ? { ..._item, [event.target.name]: event.target.value }
                    : _item
            ),
        }));
    };

    const handleAddSpecification = () =>
        setData((prev) => ({
            ...prev,
            specifications: [
                ...prev.specifications,
                { id: uuid(), name: "", value: "" },
            ],
        }));

    const handleRemoveSpecification = (specificationId) => {
        setData((prev) => ({
            ...prev,
            specifications: prev.specifications.filter(
                (_item) => _item.id !== specificationId
            ),
        }));
    };

    const handleSubmit = () => {
        setLoading(true);
        setErrors(null);

        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("short_description", data.short_description);
        formData.append("description", data.description);
        formData.append("price", data.price);
        formData.append("discount", data.discount);
        formData.append("stock", data.stock);
        formData.append("published", data.published);
        formData.append("category_id", data.category_id);
        formData.append("specifications", JSON.stringify(data.specifications));
        if (data.thumbnail) {
            formData.append("thumbnail", data.thumbnail, data.thumbnail?.name);
        }
        data.images.forEach((img) =>
            formData.append("images[]", img, img.name)
        );

        createProduct(formData)
            .then((response) => {
                notification.success("Product created successfully");
                navigate(
                    PUBLIC_URL +
                        "/products/" +
                        response.data.data.slug +
                        "/edit"
                );
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
                notification.failed("Failed to create product");
            })
            .finally(() => setLoading(false));
    };

    const handleThumbnailDrop = (files) => {
        setData((prevState) => ({ ...prevState, thumbnail: files[0] }));
    };

    const handleThumbnailRemove = () => {
        setData((prevState) => ({ ...prevState, thumbnail: null }));
    };

    const handleImagesDrop = (files) => {
        setData((prevState) => ({
            ...prevState,
            images: [...prevState.images, ...files],
        }));
    };

    const handleImageRemove = (_fileName) =>
        setData((prevState) => ({
            ...prevState,
            images: prevState.images.filter(
                (_image) => _image.name !== _fileName
            ),
        }));

    const actions = (
        <Button
            type="button"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit}
        >
            Submit
        </Button>
    );

    return (
        <Admin
            sectionTitle="Add Product"
            selected={"Products"}
            actions={actions}
        >
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tab}
                                onChange={(event, newValue) => setTab(newValue)}
                            >
                                <Tab label="Basic Info" />
                                <Tab label="Images" />
                                <Tab label="Specifications" />
                            </Tabs>
                        </Box>
                        <TabPanel value={tab} index={0}>
                            <TextField
                                type={"text"}
                                name="title"
                                label="Title"
                                defaultValue={data.title}
                                onChange={handleChange}
                                sx={{
                                    marginY: 1,
                                    width: "100%",
                                }}
                            />

                            <Editor
                                placeholder={"Short Description"}
                                defaultValue={data.short_description}
                                onChange={(value) =>
                                    setData((prev) => ({
                                        ...prev,
                                        short_description: value,
                                    }))
                                }
                                className="ql-short-description"
                            />

                            <Editor
                                placeholder={"Description"}
                                defaultValue={data.description}
                                onChange={(value) =>
                                    setData((prev) => ({
                                        ...prev,
                                        description: value,
                                    }))
                                }
                                className="ql-description"
                            />

                            <TextField
                                type={"number"}
                                name="price"
                                label="Price($)"
                                defaultValue={data.price}
                                onChange={handleChange}
                                sx={{
                                    marginY: 1,
                                    width: "100%",
                                }}
                            />

                            <TextField
                                type={"number"}
                                name="discount"
                                label="Discount($)"
                                defaultValue={data.discount}
                                onChange={handleChange}
                                sx={{
                                    marginY: 1,
                                    width: "100%",
                                }}
                            />

                            <TextField
                                type={"number"}
                                name="stock"
                                label="Stock"
                                defaultValue={data.stock}
                                onChange={handleChange}
                                sx={{
                                    marginY: 1,
                                    width: "100%",
                                }}
                            />

                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Published"
                                defaultChecked={data.published}
                                onChange={(e) =>
                                    setData((prev) => ({
                                        ...prev,
                                        published: e.target.checked ? 1 : 0,
                                    }))
                                }
                                sx={{
                                    marginY: 1,
                                    width: "100%",
                                }}
                            />

                            <FormControl
                                sx={{
                                    marginY: 1,
                                    width: "100%",
                                }}
                            >
                                <InputLabel>Category</InputLabel>
                                <Select
                                    label="Category"
                                    name="category_id"
                                    defaultValue={data.category_id}
                                    onChange={handleChange}
                                >
                                    {categories?.map((_item) => (
                                        <MenuItem value={_item.id}>
                                            {_item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <InputLabel sx={{ marginY: 2 }}>
                                {" "}
                                <strong>Thumbnail</strong>{" "}
                            </InputLabel>
                            <MyDropzone
                                title="Drag thumbnail here"
                                subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                                maxFilesCount={1}
                                acceptableTypes={{
                                    "image/png": [],
                                    "image/jpeg": [],
                                }}
                                defaultFiles={[data.thumbnail]}
                                handleDrop={handleThumbnailDrop}
                                handleRemove={handleThumbnailRemove}
                            />

                            <InputLabel sx={{ marginY: 2 }}>
                                {" "}
                                <strong>Product Images</strong>{" "}
                            </InputLabel>
                            <MyDropzone
                                title="Drag product images here"
                                subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                                maxFilesCount={10}
                                acceptableTypes={{
                                    "image/png": [],
                                    "image/jpeg": [],
                                }}
                                defaultFiles={data.images}
                                handleDrop={handleImagesDrop}
                                handleRemove={handleImageRemove}
                            />
                        </TabPanel>
                        {/* Specifications */}
                        <TabPanel value={tab} index={2}>
                            {data.specifications.map((specification) => (
                                <Grid
                                    container
                                    spacing={1}
                                    key={specification.id}
                                >
                                    <Grid item>
                                        <TextField
                                            type={"text"}
                                            name="name"
                                            label="Name"
                                            defaultValue={specification?.name}
                                            onChange={(event) =>
                                                handleSpecificationChange(
                                                    specification.id,
                                                    event
                                                )
                                            }
                                            sx={{
                                                marginY: 1,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type={"text"}
                                            name="value"
                                            label="Value"
                                            defaultValue={specification?.value}
                                            onChange={(event) =>
                                                handleSpecificationChange(
                                                    specification.id,
                                                    event
                                                )
                                            }
                                            sx={{
                                                marginY: 1,
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{ alignSelf: "center" }}
                                        onClick={() =>
                                            handleRemoveSpecification(
                                                specification.id
                                            )
                                        }
                                    >
                                        <IconButton>
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleAddSpecification}
                            >
                                Add new specification
                            </Button>
                        </TabPanel>
                    </>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={errors} />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Create;
