import axiosService from "common/axiosService";
import { API_URL } from "common/defines";
import { renderQuery } from "common/utils";

export const getBlogs = async (query) => {
    return await axiosService.get(API_URL + "/blogs" + renderQuery(query));
};

export const getBlog = async (slug) => {
    return await axiosService.get(API_URL + "/blogs/" + slug);
};

export const createBlog = async (data) => {
    return await axiosService.post(API_URL + "/blogs", data);
};

export const updateBlog = async (slug, data) => {
    return await axiosService.post(API_URL + "/blogs/" + slug, data);
};

export const deleteBlog = async (slug) => {
    return await axiosService.delete(API_URL + "/blogs/" + slug);
};
