import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Button, Divider, Grid, IconButton, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import Dialog from "components/Dialog/Dialog";
import { getCoupons, addCoupons, deleteCoupons } from "api/coupons";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";
import ValidationErrors from "components/ValidationErrors";
import DataTable from "components/DataTable/DataTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";

const Index = () => {
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { notification } = useNotification();
    const [addLoading, setAddLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const [couponForm, setCouponForm] = useState({
        coupon: null,
        value: null,
        expiry: new Date(),
    });

    const handleGetCoupons = useCallback(() => {
        setLoading(true);

        getCoupons()
            .then((res) => {
                setCoupons(res.data.data);
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    const handleDelete = () => {
        setDeleteLoading(true);

        deleteCoupons(dialogContent)
            .then(() => {
                setCoupons((prev) =>
                    prev.filter((_item) => _item.id !== dialogContent)
                );
                notification.success("Coupon deleted successfully");
                handleDialog();
            })
            .catch(() => {
                notification.failed("Failed to delete Coupon");
            })
            .finally(() => setDeleteLoading(false));
    };

    const handleSubmit = () => {
        setAddLoading(true);
        console.log(couponForm);
        addCoupons(couponForm)
            .then((res) => {
                setCoupons((prev) => [res.data.data, ...prev]);
                notification.success("Coupon added successfully");
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => setAddLoading(false));
    };

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    useEffect(() => {
        handleGetCoupons();
    }, [handleGetCoupons]);

    return (
        <Admin sectionTitle="Coupons" selected={"Coupons"}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <FetchDataWrapper
                        loading={loading}
                        data={coupons}
                        renderData={(coupons) => (
                            <div>
                                <div>
                                    <TextField
                                        type="text"
                                        required
                                        fullWidth
                                        label="Coupon"
                                        sx={{ marginTop: "1rem" }}
                                        value={couponForm.coupon}
                                        onChange={(event) => {
                                            setCouponForm((prev) => ({
                                                ...prev,
                                                coupon: event.target.value,
                                            }));
                                        }}
                                    />
                                    <TextField
                                        type="text"
                                        required
                                        fullWidth
                                        label="Value"
                                        sx={{ marginTop: "1rem" }}
                                        value={couponForm.value}
                                        onChange={(event) => {
                                            setCouponForm((prev) => ({
                                                ...prev,
                                                value: event.target.value,
                                            }));
                                        }}
                                    />
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        dateFormats={{
                                            fullDateTime: "yy-mm-dd",
                                        }}
                                    >
                                        <DatePicker
                                            label="Expiry Date *"
                                            value={couponForm.expiry}
                                            onChange={(newValue) => {
                                                setCouponForm((prev) => ({
                                                    ...prev,
                                                    expiry: newValue,
                                                }));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    sx={{ marginBottom: 2 }}
                                                />
                                            )}
                                            sx={{
                                                marginTop: "1rem",
                                                width: "100%",
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <div style={{ margin: "1rem 0" }}>
                                        <Button
                                            variant="contained"
                                            disabled={addLoading}
                                            onClick={handleSubmit}
                                        >
                                            <AddIcon /> Submit
                                        </Button>
                                    </div>
                                </div>
                                <Divider />
                                <DataTable
                                    headers={[
                                        "Coupon",
                                        "Value",
                                        "Expiry",
                                        "Actions",
                                    ]}
                                    rows={coupons.map((item) => [
                                        item.coupon,
                                        `$${item.value}`,
                                        item.expiry,
                                        <IconButton
                                            onClick={() =>
                                                handleDialog(item.id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>,
                                    ])}
                                />
                            </div>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={errors} />
                </Grid>
            </Grid>

            <Dialog
                title="Delete Coupon"
                subTitle="Are you sure you want to delete this coupon?"
                processing={deleteLoading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
