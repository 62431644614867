import React from "react";
import { Typography, List, ListItem, ListItemText, Alert } from "@mui/material";
import { isObject } from "common/utils";

export default function ValidationErrors({ errors }) {
    if (
        errors &&
        errors.errors &&
        isObject(errors.errors) &&
        Object.keys(errors.errors).length > 0
    ) {
        return (
            <Alert severity="error" sx={{ marginY: 2 }}>
                <Typography variant="string" color="error">
                    Whoops! Something went wrong.
                </Typography>
                <List>
                    {Object.keys(errors.errors).map((key, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemText color="error">
                                {errors.errors[key]}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Alert>
        );
    } else if (errors && errors.message) {
        return (
            <Alert severity="error" sx={{ marginY: 2 }}>
                <Typography variant="string" color="error">
                    Whoops! Something went wrong.
                </Typography>
            </Alert>
        );
    }

    return null;
}
