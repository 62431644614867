import axiosService from 'common/axiosService';
import { API_URL } from 'common/defines';

export const getSettings = async () => {
    return await axiosService.get(API_URL + '/settings');
};

export const updateSettings = async (data) => {
    return await axiosService.post(API_URL + '/settings', data);
};
