import React, { useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import { Grid } from "@mui/material";
import * as dashboardApi from "api/dashboard";
import AnalyticsCard from "components/AnalyticsCard";

const Dashboard = () => {
    const [analytics, setAnalytics] = useState();

    useEffect(() => {
        dashboardApi.analytics().then((response) => {
            setAnalytics(response.data);
        });
    }, []);

    let revenueData = [];
    if (analytics?.revenue) {
        const _revenueData = analytics?.revenue;
        revenueData = [
            {
                title: "This Month",
                value: `$${_revenueData.this_month?.toFixed(2)}`,
            },
            {
                title: "Last Month",
                value: `$${_revenueData.last_month?.toFixed(2)}`,
            },
            {
                title: "This Year",
                value: `$${_revenueData.this_year?.toFixed(2)}`,
            },
            {
                title: "Last Year",
                value: `$${_revenueData.last_year?.toFixed(2)}`,
            },
            {
                title: <strong style={{ color: "green" }}>Total</strong>,
                value: (
                    <strong style={{ color: "green" }}>
                        ${_revenueData.total?.toFixed(2)}
                    </strong>
                ),
            },
        ];
    }

    let ordersData = [];
    if (analytics?.orders) {
        const _ordersData = analytics?.orders;
        ordersData = [
            {
                title: <span style={{ color: "#007bff" }}>Placed</span>,
                value: (
                    <span style={{ color: "#007bff" }}>
                        {_ordersData.placed}
                    </span>
                ),
            },
            {
                title: <span style={{ color: "#28a745" }}>Confirmed</span>,
                value: (
                    <span style={{ color: "#28a745" }}>
                        {_ordersData.confirmed}
                    </span>
                ),
            },
            {
                title: <span style={{ color: "#ffc107" }}>Shipping Now</span>,
                value: (
                    <span style={{ color: "#ffc107" }}>
                        {_ordersData.shipping_now}
                    </span>
                ),
            },
            {
                title: <span style={{ color: "#17a2b8" }}>Delivered</span>,
                value: (
                    <span style={{ color: "#17a2b8" }}>
                        {_ordersData.delivered}
                    </span>
                ),
            },
            {
                title: <span style={{ color: "#28a745" }}>Completed</span>,
                value: (
                    <span style={{ color: "#28a745" }}>
                        {_ordersData.completed}
                    </span>
                ),
            },
            {
                title: <span style={{ color: "red" }}>Canceled</span>,
                value: (
                    <span style={{ color: "red" }}>{_ordersData.canceled}</span>
                ),
            },
        ];
    }

    let orderHistory = [];
    if (analytics?.orderHistory) {
        const _orderHistory = analytics?.orderHistory;
        orderHistory = [
            { title: "This Month", value: _orderHistory.this_month },
            { title: "Last Month", value: _orderHistory.last_month },
            { title: "This Year", value: _orderHistory.this_year },
            { title: "Last Year", value: _orderHistory.last_year },
            { title: "Total", value: _orderHistory.total },
        ];
    }

    let productsData = [];
    if (analytics?.products) {
        const _productsData = analytics?.products;
        productsData = [
            { title: "Total", value: _productsData.total },
            { title: "Published", value: _productsData.published },
            { title: "Unpublished", value: _productsData.unpublished },
            { title: "Stock Out", value: _productsData.stock_out },
            {
                title: "Average Price",
                value: `$${_productsData.average_price}`,
            },
        ];
    }

    return (
        <Admin selected={"Dashboard"}>
            <Grid container gap={2} marginTop={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard title={"Revenue"} data={revenueData} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard title={"Orders"} data={ordersData} />
                </Grid>
            </Grid>
            <Grid container gap={2} marginTop={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard
                        title={"Order History"}
                        data={orderHistory}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard title={"Products"} data={productsData} />
                </Grid>
            </Grid>
            <Grid container gap={2} marginTop={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard
                        title={"Product Category"}
                        data={[
                            {
                                title: "Total",
                                value: analytics?.categories.total,
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard
                        title={"Customers"}
                        data={[
                            {
                                title: "Total",
                                value: analytics?.customers.total,
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <AnalyticsCard
                        title={"Blog Post"}
                        data={[
                            { title: "Total", value: analytics?.blogs.total },
                        ]}
                    />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Dashboard;
