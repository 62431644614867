import { Button, Card, Grid, TextField } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import React, { useState } from "react";
import Admin from "components/Layouts/Admin";
import { Link, useNavigate } from "react-router-dom";
import { createAdmin } from "api/admins";
import { PUBLIC_URL } from "common/defines";
import ValidationErrors from "components/ValidationErrors";
import { useNotification } from "contexts/NotificationContext";

const Create = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { notification } = useNotification();

    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        confirm_password: "",
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors(null);

        createAdmin(data)
            .then(() => {
                notification.success("Admin created successfully");
                navigate(PUBLIC_URL + "/admins");
            })
            .catch((_errors) => setErrors(_errors.response.data))
            .finally(() => setLoading(false));
    };

    const actions = (
        <Link to={"/admins"}>
            <Button
                variant="contained"
                size="small"
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>
        </Link>
    );

    return (
        <Admin sectionTitle="Add admin" selected={"Admins"} actions={actions}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            type="text"
                            name="name"
                            value={data.name}
                            autoComplete="name"
                            onChange={handleChange}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        />

                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            name="email"
                            value={data.email}
                            autoComplete="email"
                            onChange={handleChange}
                            fullWidth
                            sx={{ marginY: 2 }}
                        />

                        <TextField
                            label="Password"
                            variant="outlined"
                            type="password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                            fullWidth
                            sx={{ marginBottom: 2 }}
                        />

                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            name="password_confirmation"
                            value={data.password_confirmation}
                            onChange={handleChange}
                            fullWidth
                            sx={{ marginBottom: 2 }}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            sx={{ display: "block", width: "100%", marginY: 3 }}
                        >
                            Submit
                        </Button>
                    </form>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={errors} />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Create;
