import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Grid,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import MyDropzone from "components/MyDropzone/MyDropzone";
import { useParams } from "react-router-dom";
import { useCategory } from "contexts/CategoryContext";
import { deleteProductImage, editProduct, getProduct } from "api/products";
import TabPanel from "components/TabPanel";
import uuid from "react-uuid";
import { useNotification } from "contexts/NotificationContext";
import FetchDataWrapper from "components/FetchDataWrapper";
import ValidationErrors from "components/ValidationErrors";
import Editor from "components/Editor";

const Edit = () => {
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const { categories } = useCategory();
    const [product, setProduct] = useState();
    const { slug } = useParams();
    const [tab, setTab] = useState(0);
    const { notification } = useNotification();

    const [data, setData] = useState({
        title: "",
        short_description: "",
        description: "",
        price: 0.0,
        discount: 0.0,
        stock: 0,
        published: true,
        thumbnail: null,
        images: [],
        category_id: null,
        specifications: [],
    });

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSpecificationChange = (specificationId, event) => {
        setData((prev) => ({
            ...prev,
            specifications: prev.specifications.map((_item) =>
                _item.id === specificationId
                    ? { ..._item, [event.target.name]: event.target.value }
                    : _item
            ),
        }));
    };

    const handleAddSpecification = () =>
        setData((prev) => ({
            ...prev,
            specifications: [
                ...prev.specifications,
                { id: uuid(), name: "", value: "" },
            ],
        }));

    const handleRemoveSpecification = (specificationId) => {
        setData((prev) => ({
            ...prev,
            specifications: prev.specifications.filter(
                (_item) => _item.id !== specificationId
            ),
        }));
    };

    const handleSubmit = () => {
        setFormLoading(true);
        setFormErrors(null);

        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("short_description", data.short_description);
        formData.append("description", data.description);
        formData.append("price", data.price);
        formData.append("discount", data.discount);
        formData.append("stock", data.stock);
        formData.append("published", data.published);
        formData.append("category_id", data.category_id);
        formData.append("specifications", JSON.stringify(data.specifications));
        if (data.thumbnail) {
            formData.append("thumbnail", data.thumbnail, data.thumbnail.name);
        }
        data.images?.forEach((img) =>
            formData.append("images[]", img, img.name)
        );

        editProduct(product?.slug, formData)
            .then((response) => {
                setProduct(response.data.data);
                notification.success("Product updated successfully");
            })
            .catch((_errors) => {
                setFormErrors(_errors.response.data);
                notification.failed("Failed to update product");
            })
            .finally(() => setFormLoading(false));
    };

    const handleThumbnailDrop = (files) => {
        setData((prevState) => ({ ...prevState, thumbnail: files[0] }));
    };

    const handleThumbnailRemove = () => {
        setData((prevState) => ({ ...prevState, thumbnail: null }));
    };

    const handleImagesDrop = (files) => {
        setData((prevState) => ({ ...prevState, images: files }));
    };

    const handleImageRemove = (_fileName) =>
        setData((prevState) => ({
            ...prevState,
            images: prevState.images.filter(
                (_image) => _image.name !== _fileName
            ),
        }));

    const handleGetProduct = useCallback(() => {
        setLoading(true);
        setErrors(null);

        getProduct(slug)
            .then((response) => {
                const _product = response.data.data;
                setErrors(false);
                setProduct(_product);
                setData((prev) => ({
                    ...prev,
                    title: _product.title,
                    short_description: _product.short_description,
                    description: _product.description,
                    price: _product.price,
                    discount: _product.discount,
                    stock: _product.stock,
                    published: _product.published,
                    category_id: _product.category?.id,
                    specifications: _product.specifications,
                }));
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => setLoading(false));
    }, [slug]);

    const handleImageDelete = (id) => {
        deleteProductImage(id)
            .then(() => {
                setErrors(false);
                setProduct((prev) => ({
                    ...prev,
                    images: prev.images?.filter((_item) => _item.id !== id),
                }));
            })
            .catch(() => setErrors(true))
            .finally(() => setLoading(false));
    };

    const actions = (
        <Button
            type="button"
            variant="contained"
            disabled={formLoading}
            onClick={handleSubmit}
        >
            Save Changes
        </Button>
    );

    useEffect(() => {
        handleGetProduct();
    }, [handleGetProduct]);

    return (
        <Admin
            sectionTitle="Edit Product"
            selected={"Products"}
            actions={actions}
        >
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={product}
                renderData={(product) => (
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                            <>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <Tabs
                                        value={tab}
                                        onChange={(event, newValue) =>
                                            setTab(newValue)
                                        }
                                    >
                                        <Tab label="Basic Info" />
                                        <Tab label="Images" />
                                        <Tab label="Specifications" />
                                    </Tabs>
                                </Box>
                                {/* Basic Info */}
                                <TabPanel value={tab} index={0}>
                                    <TextField
                                        type={"text"}
                                        name="title"
                                        label="Title"
                                        defaultValue={product?.title}
                                        onChange={handleChange}
                                        sx={{
                                            marginY: 1,
                                            width: "100%",
                                        }}
                                    />

                                    <Editor
                                        placeholder={"Short Description"}
                                        defaultValue={
                                            product?.short_description
                                        }
                                        onChange={(value) =>
                                            setData((prev) => ({
                                                ...prev,
                                                short_description: value,
                                            }))
                                        }
                                        className="ql-short-description"
                                    />

                                    <Editor
                                        placeholder={"Description"}
                                        defaultValue={product?.description}
                                        onChange={(value) =>
                                            setData((prev) => ({
                                                ...prev,
                                                description: value,
                                            }))
                                        }
                                        className="ql-description"
                                    />

                                    <TextField
                                        type={"number"}
                                        name="price"
                                        label="Price($)"
                                        defaultValue={product?.price}
                                        onChange={handleChange}
                                        sx={{
                                            marginY: 1,
                                            width: "100%",
                                        }}
                                    />

                                    <TextField
                                        type={"number"}
                                        name="discount"
                                        label="Discount($)"
                                        defaultValue={product?.discount}
                                        onChange={handleChange}
                                        sx={{
                                            marginY: 1,
                                            width: "100%",
                                        }}
                                    />

                                    <TextField
                                        type={"number"}
                                        name="stock"
                                        label="Stock"
                                        defaultValue={product?.stock}
                                        onChange={handleChange}
                                        sx={{
                                            marginY: 1,
                                            width: "100%",
                                        }}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={
                                                    product?.published
                                                }
                                            />
                                        }
                                        label="Published"
                                        onChange={(e) =>
                                            setData((prev) => ({
                                                ...prev,
                                                published: e.target.checked
                                                    ? 1
                                                    : 0,
                                            }))
                                        }
                                        sx={{
                                            marginY: 1,
                                            width: "100%",
                                        }}
                                    />

                                    <FormControl
                                        sx={{
                                            marginY: 1,
                                            width: "100%",
                                        }}
                                    >
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            label="Category"
                                            name="category_id"
                                            defaultValue={product?.category?.id}
                                            onChange={handleChange}
                                        >
                                            {categories?.map((_item) => (
                                                <MenuItem value={_item.id}>
                                                    {_item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TabPanel>

                                {/* Images */}
                                <TabPanel value={tab} index={1}>
                                    <InputLabel sx={{ marginY: 2 }}>
                                        {" "}
                                        <strong>Thumbnail</strong>{" "}
                                    </InputLabel>
                                    <MyDropzone
                                        title="Drag thumbnail here"
                                        subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                                        maxFilesCount={1}
                                        acceptableTypes={{
                                            "image/png": [],
                                            "image/jpeg": [],
                                        }}
                                        defaultFiles={[data.thumbnail]}
                                        handleDrop={handleThumbnailDrop}
                                        handleRemove={handleThumbnailRemove}
                                    />
                                    <ImageList
                                        sx={{
                                            maxWidth: 500,
                                            maxHeight: 450,
                                        }}
                                    >
                                        <ImageListItem>
                                            <img
                                                src={`${product?.thumbnail}`}
                                                alt=""
                                            />
                                        </ImageListItem>
                                    </ImageList>
                                    <InputLabel sx={{ marginY: 2 }}>
                                        <strong>Product Images</strong>
                                    </InputLabel>
                                    <MyDropzone
                                        title="Drag product images here"
                                        subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                                        maxFilesCount={10}
                                        acceptableTypes={{
                                            "image/png": [],
                                            "image/jpeg": [],
                                        }}
                                        defaultFiles={data.images}
                                        handleDrop={handleImagesDrop}
                                        handleRemove={handleImageRemove}
                                    />
                                    <ImageList
                                        sx={{ maxWidth: 500, maxHeight: 450 }}
                                    >
                                        {product?.images?.map((img) => (
                                            <ImageListItem key={img.id}>
                                                <img
                                                    src={`${img.path}`}
                                                    alt=""
                                                />
                                                <ImageListItemBar
                                                    actionIcon={
                                                        <IconButton
                                                            sx={{
                                                                color: "rgba(255, 255, 255, 0.54)",
                                                            }}
                                                            onClick={() =>
                                                                handleImageDelete(
                                                                    img.id
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </TabPanel>

                                {/* Specifications */}
                                <TabPanel value={tab} index={2}>
                                    {data.specifications?.map(
                                        (specification) => (
                                            <Grid
                                                container
                                                spacing={1}
                                                key={specification.id}
                                            >
                                                <Grid item>
                                                    <TextField
                                                        type={"text"}
                                                        name="name"
                                                        label="Name"
                                                        defaultValue={
                                                            specification?.name
                                                        }
                                                        onChange={(event) =>
                                                            handleSpecificationChange(
                                                                specification.id,
                                                                event
                                                            )
                                                        }
                                                        sx={{
                                                            marginY: 1,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        type={"text"}
                                                        name="value"
                                                        label="Value"
                                                        defaultValue={
                                                            specification?.value
                                                        }
                                                        onChange={(event) =>
                                                            handleSpecificationChange(
                                                                specification.id,
                                                                event
                                                            )
                                                        }
                                                        sx={{
                                                            marginY: 1,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sx={{ alignSelf: "center" }}
                                                    onClick={() =>
                                                        handleRemoveSpecification(
                                                            specification.id
                                                        )
                                                    }
                                                >
                                                    <IconButton>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleAddSpecification}
                                    >
                                        Add new specification
                                    </Button>
                                </TabPanel>
                            </>
                        </Grid>
                        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                            <ValidationErrors errors={formErrors} />
                        </Grid>
                    </Grid>
                )}
            />
        </Admin>
    );
};

export default Edit;
