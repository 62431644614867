import { Add as AddIcon } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import Dialog from "components/Dialog/Dialog";
import { Link } from "react-router-dom";
import { PUBLIC_URL } from "common/defines";
import {
    deleteHomepageSlider,
    getHomepageSlider,
    updateHomepageSlider,
} from "api/ui";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";
import ValidationErrors from "components/ValidationErrors";

const Index = () => {
    const [homepageSliders, setHomepageSliders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const { notification } = useNotification();
    const [updateSliderLoading, setUpdateSliderLoading] = useState(false);
    const [updateSliderErrors, setUpdateSliderErrors] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const actions = (
        <Link to={PUBLIC_URL + "/ui/homepage-slider/create"}>
            <Button variant="contained" size="small" startIcon={<AddIcon />}>
                Add New
            </Button>
        </Link>
    );

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    const handleGetHomepageSliders = useCallback(() => {
        setLoading(true);

        getHomepageSlider()
            .then((res) => {
                setHomepageSliders(res.data.data);
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    const handleDelete = () => {
        setDeleteLoading(true);

        deleteHomepageSlider(dialogContent.id)
            .then(() => {
                setHomepageSliders((prev) =>
                    prev.filter((_item) => _item.id !== dialogContent.id)
                );
                notification.success("Slider deleted successfully");
                handleDialog();
            })
            .catch(() => {
                notification.failed("Failed to delete slider");
            })
            .finally(() => setDeleteLoading(false));
    };

    const handleSubmit = useCallback(
        (e, sliderId) => {
            e.preventDefault();
            setUpdateSliderLoading(true);
            setUpdateSliderErrors(null);

            const form = e.target;
            const formData = new FormData(form);

            updateHomepageSlider(sliderId, formData)
                .then((res) => {
                    const updatedSlider = res.data.data;
                    setHomepageSliders((prev) =>
                        prev.map((item) =>
                            item.id === updatedSlider.id ? updatedSlider : item
                        )
                    );
                    notification.success("Slider updated successfully");
                })
                .catch((_errors) => {
                    setUpdateSliderErrors((prev) => [
                        {
                            id: sliderId,
                            errors: _errors.response.data,
                        },
                    ]);
                })
                .finally(() => setUpdateSliderLoading(false));
        },
        [notification]
    );

    useEffect(() => {
        handleGetHomepageSliders();
    }, [handleGetHomepageSliders]);

    return (
        <Admin
            sectionTitle="Homepage Slider"
            selected={"Homepage Slider"}
            actions={actions}
        >
            <FetchDataWrapper
                loading={loading}
                data={homepageSliders}
                renderData={(homepageSliders) => (
                    <Grid container flexDirection={"column"}>
                        {homepageSliders.map((slider, index) => (
                            <Grid item>
                                <Card sx={{ my: 2 }}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            md={8}
                                            order={{ xs: 2, md: 1 }}
                                        >
                                            <form
                                                onSubmit={(e) =>
                                                    handleSubmit(e, slider.id)
                                                }
                                            >
                                                <CardHeader
                                                    title={`Slider: ${
                                                        index + 1
                                                    }`}
                                                />
                                                <CardContent>
                                                    <InputLabel>
                                                        Desktop
                                                        Image:(1200x600)px
                                                    </InputLabel>
                                                    <TextField
                                                        type="file"
                                                        name="desktop"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            mb: 2,
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <img
                                                        src={slider.desktop}
                                                        alt="desktop slider"
                                                        style={{
                                                            maxWidth: "200px",
                                                            maxHeight: "200px",
                                                            display: "block",
                                                        }}
                                                    />
                                                    <InputLabel sx={{ mt: 1 }}>
                                                        Mobile Image:
                                                        (480x540)px
                                                    </InputLabel>
                                                    <TextField
                                                        type="file"
                                                        name="mobile"
                                                        variant="outlined"
                                                        sx={{
                                                            mb: 2,
                                                            width: "100%",
                                                        }}
                                                        size="small"
                                                    />
                                                    <img
                                                        src={slider.mobile}
                                                        alt="mobile slider"
                                                        style={{
                                                            maxWidth: "200px",
                                                            maxHeight: "150px",
                                                            display: "block",
                                                        }}
                                                    />

                                                    <TextField
                                                        name="action"
                                                        label="Action"
                                                        defaultValue={
                                                            slider.action
                                                        }
                                                        sx={{
                                                            marginY: 2,
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <div>
                                                        <strong>
                                                            E.g. /shop
                                                        </strong>
                                                    </div>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        size="small"
                                                        onClick={() =>
                                                            handleDialog(slider)
                                                        }
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        disabled={
                                                            updateSliderLoading
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </CardActions>
                                            </form>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            order={{ xs: 1, md: 2 }}
                                        >
                                            <ValidationErrors
                                                errors={
                                                    updateSliderErrors?.find(
                                                        (_item) =>
                                                            _item.id ===
                                                            slider.id
                                                    )?.errors
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            />

            <Dialog
                title="Delete Slider"
                subTitle="Are you sure you want to delete this slider?"
                processing={deleteLoading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
