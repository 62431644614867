export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const API_URL = process.env.REACT_APP_API_URL;

export const ORDER_PLACED = 'Placed';
export const ORDER_CONFIRMED = 'Confirmed';
export const ORDER_SHIPPING_NOW = 'Shipping Now';
export const ORDER_DELIVERED = 'Delivered';
export const ORDER_COMPLETED = 'Completed';
export const ORDER_CANCELED = 'Canceled';
