import {
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import uuid from "react-uuid";

const DataTable = ({
    headers,
    rows,
    totalPage = null,
    currentPage = null,
    handlePageChange = null,
}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell
                                key={uuid()}
                                sx={{ textTransform: "uppercase" }}
                            >
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow hover key={uuid()}>
                            {row.map((cell) => (
                                <TableCell align="left" key={uuid()}>
                                    {cell}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {totalPage && currentPage && handlePageChange && (
                <Stack spacing={2} sx={{ marginTop: 3 }}>
                    <Pagination
                        color="primary"
                        count={totalPage}
                        page={currentPage}
                        onChange={(e, value) => handlePageChange(value)}
                        sx={{ display: "flex", justifyContent: "center" }}
                    />
                </Stack>
            )}
        </TableContainer>
    );
};

export default React.memo(DataTable);
