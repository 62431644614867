import { Button, Grid, TextField } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import { Link, useParams } from "react-router-dom";
import { updateAdmin, getAdmin } from "api/admins";
import { useNotification } from "contexts/NotificationContext";
import ValidationErrors from "components/ValidationErrors";
import FetchDataWrapper from "components/FetchDataWrapper";

const Edit = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const { id } = useParams();
    const { notification } = useNotification();
    const [admin, setAdmin] = useState(null);

    const [data, setData] = useState({
        name: "",
        email: "",
        password: null,
        confirm_password: null,
    });

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormLoading(true);
        setFormErrors(null);

        updateAdmin(id, data)
            .then(() => {
                notification.success("Admin updated successfully");
            })
            .catch((_errors) => {
                setFormErrors(_errors.response.data);
            })
            .finally(() => setFormLoading(false));
    };

    const handleGetAdmin = useCallback(() => {
        setLoading(true);

        getAdmin(id)
            .then((response) => {
                const adminResponse = response.data.data;
                setAdmin(adminResponse);
                setData(() => ({
                    name: adminResponse.name,
                    email: adminResponse.email,
                }));
            })
            .catch((errors) => {
                setErrors(errors.response.data);
            })
            .finally(() => setLoading(false));
    }, [id]);

    useEffect(() => {
        handleGetAdmin();
    }, [handleGetAdmin]);

    const actions = (
        <Link to={"/admins"}>
            <Button
                variant="contained"
                size="small"
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>
        </Link>
    );

    return (
        <Admin sectionTitle="Edit admin" selected={"Admins"} actions={actions}>
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={admin}
                renderData={() => (
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    type="text"
                                    name="name"
                                    value={data.name}
                                    autoComplete="name"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginTop: 2 }}
                                />

                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    autoComplete="email"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginY: 2 }}
                                />

                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                />

                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    type="password"
                                    name="password_confirmation"
                                    value={data.password_confirmation}
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={formLoading}
                                    sx={{
                                        display: "block",
                                        width: "100%",
                                        marginY: 3,
                                    }}
                                >
                                    Save changes
                                </Button>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                            <ValidationErrors errors={formErrors} />
                        </Grid>
                    </Grid>
                )}
            />
        </Admin>
    );
};

export default Edit;
