import React, { useCallback } from "react";
import { upload } from "api/publicUpload";
import { useNotification } from "contexts/NotificationContext";
import { useMemo, useRef } from "react";

const ReactQuill = require("react-quill");

const Editor = ({ defaultValue, placeholder, className, onChange }) => {
    const { notification } = useNotification();
    const quillRef = useRef();

    const insertToEditor = (url) => {
        const range = quillRef.current.getEditorSelection();
        let position = range ? range.index : 0;
        quillRef.current.getEditor().insertEmbed(position, "image", url);
    };

    const saveToServer = useCallback(
        async (file) => {
            const fd = new FormData();
            fd.append("data", file, file?.name);
            upload(fd)
                .then((response) => insertToEditor(response.data.path))
                .catch((e) => {
                    console.log(e);
                    notification.failed("Failed to insert file");
                });
        },
        [notification]
    );

    const handleImageUpload = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                saveToServer(file);
            } else {
                console.warn("You could only upload images.");
            }
        };
    }, [saveToServer]);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                ],

                handlers: {
                    image: handleImageUpload,
                },
            },
        }),
        [handleImageUpload]
    );

    return (
        <ReactQuill
            ref={quillRef}
            theme="snow"
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            className={className}
            style={{
                margin: "0.5rem 0",
                marginTop: "1rem",
            }}
            modules={modules}
        />
    );
};

export default React.memo(Editor);
