import axiosService from "common/axiosService";
import { API_URL } from "common/defines";
import { renderQuery } from "common/utils";

export const getProducts = async (query = {}) =>
    await axiosService.get(API_URL + "/products" + renderQuery(query));

export const getProduct = async (slug) =>
    await axiosService.get(API_URL + "/products/" + slug);

export const createProduct = async (values) =>
    await axiosService.post(API_URL + "/products", values);

export const editProduct = async (productId, values) =>
    await axiosService.post(API_URL + "/products/" + productId, values);

export const deleteProductImage = async (imageId) =>
    await axiosService.delete(API_URL + "/products/images/" + imageId);

export const deleteProduct = async (productId) =>
    await axiosService.delete(API_URL + "/products/" + productId);
