import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import DataTable from "components/DataTable/DataTable";
import Dialog from "components/Dialog/Dialog";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { FRONTEND_URL, PUBLIC_URL } from "common/defines";
import { deleteBlog, getBlogs } from "api/blogs";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";

const Index = () => {
    const [blogs, setBlogs] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const [dialogLoading, setDialogLoading] = useState(false);
    const { notification } = useNotification();
    const theme = useTheme();

    const actions = (
        <Link to={PUBLIC_URL + "/blogs/create"}>
            <Button variant="contained" size="small" startIcon={<AddIcon />}>
                Add Blog
            </Button>
        </Link>
    );

    // prepare categories for datatable
    const headers = ["ID", "Thumbnail", "Title", "View", "Actions"];

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    const handleGetBlogs = useCallback((query) => {
        setLoading(true);
        setErrors(null);

        getBlogs(query)
            .then((res) => {
                setBlogs(res.data);
            })
            .catch((_errors) => setErrors(_errors.response.data))
            .finally(() => setLoading(false));
    }, []);

    const handlePageChange = (curPage) => {
        handleGetBlogs({
            page: curPage,
        });
    };

    const handleDelete = () => {
        setDialogLoading(true);

        deleteBlog(dialogContent?.slug)
            .then(() => {
                setBlogs((prev) => ({
                    ...prev,
                    data: prev.data.filter(
                        (_item) => _item.slug !== dialogContent.slug
                    ),
                }));
                notification.success("Blog deleted successfully");
                handleDialog();
            })
            .catch(() => {
                notification.failed("Failed to delete blog");
            })
            .finally(() => {
                setDialogLoading(false);
            });
    };

    useEffect(() => {
        handleGetBlogs();
    }, [handleGetBlogs]);

    const rows = blogs?.data?.map((blog) => [
        blog.id,
        <img src={blog.thumbnail} alt="" style={{ height: "50px" }} />,
        blog.title,
        <a
            href={FRONTEND_URL + "/blog/" + blog.slug}
            target="_blank"
            rel="noreferrer"
        >
            <Button variant="outlined" size="small">
                View
            </Button>
        </a>,
        <Box style={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
            <Link
                to={PUBLIC_URL + "/blogs/" + blog.slug + "/edit"}
                style={{ color: theme.palette.action.active }}
            >
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Link>
            <IconButton onClick={() => handleDialog(blog)}>
                <DeleteIcon />
            </IconButton>
        </Box>,
    ]);

    return (
        <Admin sectionTitle="Blogs" selected={"Blogs"} actions={actions}>
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={blogs?.data}
                renderData={() => (
                    <DataTable
                        headers={headers}
                        rows={rows}
                        totalPage={blogs?.meta?.last_page}
                        currentPage={blogs?.meta?.current_page}
                        handlePageChange={handlePageChange}
                    />
                )}
            />

            <Dialog
                title="Delete blog"
                subTitle="Are you sure you want to delete this blog?"
                processing={dialogLoading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
