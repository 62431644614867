import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import { getAdmins, deleteAdmin } from "api/admins";

import { Button, Box } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import AdminCard from "components/AdminCard/AdminCard";
import { Link } from "react-router-dom";
import { PUBLIC_URL } from "common/defines";
import Dialog from "components/Dialog/Dialog";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";

export default function Admins() {
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { notification } = useNotification();

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    const handleDelete = () => {
        setDeleteLoading(true);

        deleteAdmin(dialogContent?.id)
            .then((response) => {
                setAdmins(response.data.data);
            })
            .catch(() => {
                notification.failed("Failed to delete admin");
            })
            .finally(() => {
                setDeleteLoading(false);
                handleDialog();
            });
    };

    const handleGetAdmins = useCallback(() => {
        setLoading(true);
        getAdmins()
            .then((res) => {
                setAdmins(res.data.data);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        handleGetAdmins();
    }, [handleGetAdmins]);

    const actions = (
        <Link to={PUBLIC_URL + "/admins/create"}>
            <Button variant="contained" size="small" startIcon={<AddIcon />}>
                Add Admin
            </Button>
        </Link>
    );

    return (
        <Admin sectionTitle="Admins" selected={"Admins"} actions={actions}>
            <Box
                sx={{
                    flexWrap: "wrap",
                    marginTop: "1rem",
                    display: "flex",
                    gap: "0.5rem",
                }}
            >
                <FetchDataWrapper
                    loading={loading}
                    data={admins}
                    renderData={(admins) =>
                        admins?.map((admin, index) => (
                            <AdminCard
                                key={index}
                                admin={admin}
                                onDelete={() => handleDialog(admin)}
                            />
                        ))
                    }
                />
            </Box>

            <Dialog
                title="Delete Admin"
                subTitle=" Are you sure you want to delete this admin?"
                processing={deleteLoading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
}
