import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import Admin from "components/Layouts/Admin";
import { Link, useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "common/defines";
import { addHomepageSlider } from "api/ui";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNotification } from "contexts/NotificationContext";
import ValidationErrors from "components/ValidationErrors";

const Add = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { notification } = useNotification();

    const navigate = useNavigate();

    const actions = (
        <Link to={PUBLIC_URL + "/ui/homepage-slider"}>
            <Button
                variant="contained"
                size="small"
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>
        </Link>
    );

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            setLoading(true);
            setErrors(null);

            const form = e.target;
            const formData = new FormData(form);
            addHomepageSlider(formData)
                .then(() => {
                    notification.success("Slider added successfully");
                    navigate("/ui/homepage-slider");
                })
                .catch((_errors) => {
                    setErrors(_errors.response.data);
                })
                .finally(() => setLoading(false));
        },
        [navigate, notification]
    );

    return (
        <Admin
            sectionTitle="Add Homepage Slider"
            selected={"Homepage Slider"}
            actions={actions}
        >
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <CardContent>
                            <InputLabel>Desktop Image:(1200x600)px</InputLabel>
                            <TextField
                                type="file"
                                name="desktop"
                                variant="outlined"
                                size="small"
                                sx={{
                                    mb: 2,
                                    width: {
                                        xs: "100%",
                                        md: "64%",
                                    },
                                }}
                            />
                            <InputLabel sx={{ mt: 1 }}>
                                Mobile Image: (480x540)px
                            </InputLabel>
                            <TextField
                                type="file"
                                name="mobile"
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                    width: {
                                        xs: "100%",
                                        md: "64%",
                                    },
                                }}
                                size="small"
                            />

                            <TextField
                                name="action"
                                label="Action"
                                sx={{
                                    marginY: 2,
                                    width: {
                                        xs: "100%",
                                        md: "64%",
                                    },
                                }}
                            />
                            <div>
                                <strong>E.g. /shop</strong>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={loading}
                            >
                                Save
                            </Button>
                        </CardActions>
                    </form>
                </Grid>
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <ValidationErrors errors={errors} />
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Add;
