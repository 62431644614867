import {
    TextField,
    Button,
    Grid,
    InputLabel,
    ImageList,
    ImageListItem,
    Box,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import { getEventPopup, updateEventPopup } from "api/ui";
import MyDropzone from "components/MyDropzone/MyDropzone";
import { useNotification } from "contexts/NotificationContext";

const Index = () => {
    const [data, setData] = useState({
        image: null,
        visibility: false,
        popup_start_date: new Date(),
        popup_end_date: new Date(),
    });
    const { notification } = useNotification();

    const handleImageDrop = (files) => {
        setData((prevState) => ({ ...prevState, image: files[0] }));
    };

    const handleImageRemove = () => {
        setData((prevState) => ({ ...prevState, image: null }));
    };

    const submit = () => {
        const formData = new FormData();
        formData.append("visibility", data.visibility);
        formData.append(
            "popup_start_date",
            data.popup_start_date.toLocaleDateString()
        );
        formData.append(
            "popup_end_date",
            data.popup_end_date.toLocaleDateString()
        );
        if (typeof data?.image?.name == "string") {
            formData.append("image", data.image, data.image.name);
        }

        updateEventPopup(formData)
            .then((response) => {
                const _eventPopup = response.data.data;
                if (_eventPopup) {
                    setData((prevData) => ({
                        ...prevData,
                        visibility: _eventPopup.visibility,
                        popup_start_date: new Date(
                            _eventPopup.popup_start_date
                        ),
                        popup_end_date: new Date(_eventPopup.popup_end_date),
                    }));
                    notification.success("Popup updated successfully.");
                }
            })
            .catch(() => {
                //
            });
    };

    useEffect(() => {
        getEventPopup()
            .then((response) => {
                const _eventPopup = response.data.data;
                if (_eventPopup) {
                    setData(() => ({
                        image: _eventPopup.image,
                        visibility: _eventPopup.visibility,
                        popup_start_date: new Date(
                            _eventPopup.popup_start_date
                        ),
                        popup_end_date: new Date(_eventPopup.popup_end_date),
                    }));
                }
            })
            .catch(() => {
                //
            });
    }, []);

    return (
        <Admin sectionTitle="Popup" selected={"Popup"}>
            <Grid container>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <div>
                        <InputLabel sx={{ marginY: 1 }}>Image*</InputLabel>
                        <MyDropzone
                            title="Drag image here"
                            subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                            maxFilesCount={1}
                            acceptableTypes={{
                                "image/png": [],
                                "image/jpeg": [],
                            }}
                            defaultFiles={
                                typeof data?.image?.name === "string"
                                    ? [data.image]
                                    : []
                            }
                            handleDrop={handleImageDrop}
                            handleRemove={handleImageRemove}
                        />
                        {data.image && (
                            <ImageList
                                sx={{
                                    maxWidth: 500,
                                    maxHeight: 450,
                                }}
                            >
                                <ImageListItem>
                                    <img src={`${data?.image}`} alt="" />
                                </ImageListItem>
                            </ImageList>
                        )}
                    </div>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        dateFormats={{
                            fullDateTime: "yy-mm-dd",
                        }}
                    >
                        <DatePicker
                            label="Popup start date*"
                            value={data.popup_start_date}
                            onChange={(newValue) => {
                                setData((prev) => ({
                                    ...prev,
                                    popup_start_date: newValue,
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                />
                            )}
                            sx={{
                                marginTop: "1rem",
                                width: "100%",
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        dateFormats={{
                            fullDateTime: "yy-mm-dd",
                        }}
                    >
                        <DatePicker
                            label="Popup end date*"
                            value={data.popup_end_date}
                            onChange={(newValue) => {
                                setData((prev) => ({
                                    ...prev,
                                    popup_end_date: newValue,
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                />
                            )}
                            sx={{
                                marginTop: "1rem",
                                width: "100%",
                            }}
                        />
                    </LocalizationProvider>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginY: 2,
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    name="visibility"
                                    checked={data?.visibility}
                                    onChange={(event) =>
                                        setData((prevData) => ({
                                            ...prevData,
                                            visibility: event.target.checked,
                                        }))
                                    }
                                />
                            }
                            label="Visibility"
                        />
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        onClick={submit}
                        sx={{
                            display: "block",
                            width: "100%",
                            marginTop: "1rem",
                        }}
                    >
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
        </Admin>
    );
};

export default Index;
