import React from "react";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import "styles/index.css";
import ContextProvider from "contexts/ContextProvider";
import { AuthProvider } from "contexts/AuthContext";
import { CategoryProvider } from "contexts/CategoryContext";
import { NotificationProvider } from "contexts/NotificationContext";
import { BrowserRouter } from "react-router-dom";
import App from "app";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <ContextProvider
            components={[AuthProvider, NotificationProvider, CategoryProvider]}
        >
            <App />
        </ContextProvider>
    </BrowserRouter>
);
