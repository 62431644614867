import {
    Button,
    ImageList,
    ImageListItem,
    InputLabel,
    TextField,
    Grid,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import MyDropzone from "components/MyDropzone/MyDropzone";
import ValidationErrors from "components/ValidationErrors";
import { Link, useParams } from "react-router-dom";
import { getCategory, updateCategory } from "api/categories";
import { useNotification } from "contexts/NotificationContext";
import FetchDataWrapper from "components/FetchDataWrapper";

const Edit = () => {
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [formErrors, setFormErrors] = useState(null);
    const [category, setCategory] = useState();
    const { id } = useParams();
    const { notification } = useNotification();

    const [data, setData] = useState({
        name: "",
        thumbnail: null,
    });

    const handleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleSubmit = () => {
        setFormLoading(true);
        setFormErrors(null);

        const formData = new FormData();
        formData.append("name", data.name);
        if (data.thumbnail) {
            formData.append("thumbnail", data.thumbnail, data.thumbnail?.name);
        }

        updateCategory(id, formData)
            .then((response) => {
                setCategory(response.data.data);
                notification.success("Category updated");
            })
            .catch((_errors) => {
                setFormErrors(_errors.response.data);
                notification.failed("Failed to update category");
            })
            .finally(() => setFormLoading(false));
    };

    const handleThumbnailDrop = (files) => {
        setData((prevState) => ({ ...prevState, thumbnail: files[0] }));
    };

    const handleThumbnailRemove = () => {
        setData((prevState) => ({ ...prevState, thumbnail: null }));
    };

    const handleGetCategory = useCallback(() => {
        setLoading(true);
        setErrors(null);

        getCategory(id)
            .then((response) => {
                const _category = response.data.data;
                setCategory(_category);
                setData((prev) => ({
                    ...prev,
                    name: _category.name,
                }));
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => setLoading(false));
    }, [id]);

    useEffect(() => {
        handleGetCategory();
    }, [handleGetCategory]);

    const actions = (
        <Link to={"/categories"}>
            <Button
                variant="contained"
                size="small"
                startIcon={<ArrowBackIcon />}
            >
                Back
            </Button>
        </Link>
    );

    return (
        <Admin
            sectionTitle="Edit category"
            selected={"Categories"}
            actions={actions}
        >
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={category}
                renderData={(category) => (
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                            <>
                                <TextField
                                    type={"text"}
                                    name="name"
                                    label="Name"
                                    defaultValue={category?.name}
                                    onChange={handleChange}
                                    sx={{
                                        marginY: 2,
                                        width: "100%",
                                    }}
                                />

                                <InputLabel sx={{ marginY: 2 }}>
                                    {" "}
                                    Thumbnail{" "}
                                </InputLabel>
                                <MyDropzone
                                    title="Drag thumbnail here"
                                    subTitle="Only *.jpeg, *.jpg and *.png images will be accepted"
                                    maxFilesCount={1}
                                    acceptableTypes={{
                                        "image/png": [],
                                        "image/jpeg": [],
                                    }}
                                    defaultFiles={[data.thumbnail]}
                                    handleDrop={handleThumbnailDrop}
                                    handleRemove={handleThumbnailRemove}
                                    style={{ width: "100%" }}
                                />
                                <ImageList
                                    sx={{ maxWidth: 500, maxHeight: 450 }}
                                >
                                    <ImageListItem>
                                        <img
                                            src={`${category?.thumbnail}`}
                                            alt=""
                                        />
                                    </ImageListItem>
                                </ImageList>

                                <Button
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    disabled={formLoading}
                                    sx={{ display: "block", marginY: 2 }}
                                    onClick={handleSubmit}
                                >
                                    Save Changes
                                </Button>
                            </>
                        </Grid>
                        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                            <ValidationErrors errors={formErrors} />
                        </Grid>
                    </Grid>
                )}
            />
        </Admin>
    );
};

export default Edit;
