import axiosService from "common/axiosService";
import { API_URL } from "common/defines";

export const getHomepageSlider = async () => {
    return await axiosService.get(API_URL + "/ui/homepage-slider");
};

export const addHomepageSlider = async (data) => {
    return await axiosService.post(API_URL + "/ui/homepage-slider", data);
};

export const updateHomepageSlider = async (id, data) => {
    return await axiosService.post(API_URL + "/ui/homepage-slider/" + id, data);
};

export const deleteHomepageSlider = async (id) => {
    return await axiosService.delete(API_URL + "/ui/homepage-slider/" + id);
};

export const getEventPopup = async () => {
    return await axiosService.get(API_URL + "/ui/event-popup");
};

export const updateEventPopup = async (data) => {
    return await axiosService.post(API_URL + "/ui/event-popup/", data);
};
