import React from "react";
import {
    Button,
    LinearProgress,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const Dialog = ({
    show = false,
    title = "",
    subTitle = "",
    body = null,
    processing = false,
    handleDialog = () => {},
    handleConfirm = () => {},
}) => {
    return (
        <MuiDialog open={show} onClose={handleDialog}>
            {processing && <LinearProgress />}
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{subTitle}</DialogContentText>
                {body ?? null}
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    variant="outlined"
                    disabled={processing}
                    autoFocus
                    onClick={handleDialog}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    disabled={processing}
                    onClick={handleConfirm}
                >
                    Confirm
                </Button>
            </DialogActions>
        </MuiDialog>
    );
};

export default Dialog;
