import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import DataTable from "components/DataTable/DataTable";
import { IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { deleteCustomer, getCustomers } from "api/customers";
import Dialog from "components/Dialog/Dialog";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";

const Index = () => {
    const [customers, setCustomers] = useState({
        loading: false,
        errors: null,
        data: null,
    });
    const [dialog, setDialog] = useState({
        show: false,
        loading: false,
        content: null,
    });
    const { notification } = useNotification();

    const handleDialog = (item = null) => {
        setDialog((prev) => ({
            ...prev,
            show: !prev.show,
            content: prev.content ? null : item,
        }));
    };

    const handleGetCustomers = useCallback((query) => {
        setCustomers({ loading: true, error: null, data: null });
        getCustomers(query)
            .then((res) => {
                setCustomers({ loading: false, error: null, data: res.data });
            })
            .catch((errors) =>
                setCustomers({ loading: false, error: errors, data: null })
            );
    }, []);

    const handlePageChange = (curPage) => {
        handleGetCustomers({
            page: curPage,
        });
    };

    const handleDelete = () => {
        setDialog((prev) => ({ ...prev, loading: true }));

        deleteCustomer(dialog.content.id)
            .then(() => {
                setCustomers((_prev) => ({
                    ..._prev,
                    data: {
                        ..._prev.data,
                        data: _prev.data.data.filter(
                            (_item) => _item.id !== dialog.content.id
                        ),
                    },
                }));
                notification.success("Customer deleted successfully");
                handleDialog();
            })
            .catch(() => {
                notification.failed("Failed to delete customer");
            })
            .finally(() => {
                setDialog((prev) => ({ ...prev, loading: false }));
            });
    };

    useEffect(() => {
        handleGetCustomers();
    }, [handleGetCustomers]);

    // prepare categories for datatable
    const headers = [
        "ID",
        "First Name",
        "Last Name",
        "Email",
        "Orders",
        "Action",
    ];

    const rows = customers.data?.data?.map((customer) => [
        customer.id,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.order_count,
        <IconButton title="Delete">
            <DeleteIcon onClick={() => handleDialog(customer)} />
        </IconButton>,
    ]);

    return (
        <Admin sectionTitle="Customers" selected={"Customers"}>
            <FetchDataWrapper
                loading={customers.loading}
                errors={customers.errors}
                data={customers.data?.data}
                renderData={(data) => (
                    <DataTable
                        headers={headers}
                        rows={rows}
                        totalPage={customers.data.meta?.last_page}
                        currentPage={customers.data.meta?.current_page}
                        handlePageChange={handlePageChange}
                    />
                )}
            />
            <Dialog
                title="Delete Customer"
                subTitle="Are you sure you want to delete this customer?"
                processing={dialog.loading}
                show={dialog.show}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
