import React, { useState } from "react";
import Guest from "components/Layouts/Guest";
import { TextField, Button, Card, CardContent } from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import { useSearchParams } from "react-router-dom";

export default function ResetPassword() {
    const { loading, resetPassword } = useAuth();
    let [searchParams] = useSearchParams();

    const [data, setData] = useState({
        password: "",
        password_confirmation: "",
    });

    const onHandleChange = (event) => {
        setData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const submit = () => {
        resetPassword({
            ...data,
            token: searchParams.get("token"),
            email: searchParams.get("email"),
        });
    };

    return (
        <Guest>
            <Card variant="outlined" sx={{ borderRadius: "2rem" }}>
                <CardContent>
                    <form>
                        <TextField
                            label="Password"
                            variant="outlined"
                            type="password"
                            name="password"
                            onChange={onHandleChange}
                            fullWidth
                            sx={{ marginY: 1 }}
                        />

                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            name="password_confirmation"
                            onChange={onHandleChange}
                            fullWidth
                            sx={{ marginY: 1 }}
                        />

                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            disabled={loading}
                            sx={{ display: "block", width: "100%", marginY: 2 }}
                            onClick={submit}
                        >
                            Reset Password
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Guest>
    );
}
