import { Box, CircularProgress, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { isObject } from "lodash";

const FetchDataWrapper = ({ loading, errors, data, renderData }) => {
    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (
        (errors?.errors &&
            isObject(errors.errors) &&
            Object.keys(errors.errors).length > 0) ||
        errors?.message
    ) {
        return (
            <Box sx={{ width: "100%", textAlign: "center", padding: 2 }}>
                <ErrorOutlineIcon color={"error"} />
                <Typography variant="h5" component="h2" gutterBottom>
                    Error
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Someting went wrong. Please try again later.
                </Typography>
            </Box>
        );
    }

    if (data && renderData) {
        return renderData(data);
    }

    return null;
};

export default FetchDataWrapper;
