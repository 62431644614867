import React from "react";
import logo from "assets/images/logo.png";

export default function ApplicationLogo({ className }) {
    return (
        <img
            src={logo}
            style={{ width: "250px", padding: "1rem 0" }}
            alt="YourPaints"
        />
    );
}
