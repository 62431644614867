import { Route, Routes, useNavigate } from "react-router-dom";

import Dashboard from "pages/Dashboard";

import Login from "pages/Auth/Login";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";

import Categories from "pages/Categories/Index";
import CategoryCreate from "pages/Categories/Create";
import CategoryUpdate from "pages/Categories/Edit";

import Products from "pages/Products/Index";
import ProductCreate from "pages/Products/Create";
import ProductEdit from "pages/Products/Edit";

import Coupons from "pages/Coupons/Index";

import Orders from "pages/Orders/Index";
import OrderShow from "pages/Orders/Show";
import OrderCreate from "pages/Orders/Create";

import Customers from "pages/Customers/Index";

import Blogs from "pages/Blogs/Index";
import BlogCreate from "pages/Blogs/Create";
import BlogEdit from "pages/Blogs/Edit";

import HomepageSlider from "pages/UI/HomepageSlider/Index";
import AddHomepageSlider from "pages/UI/HomepageSlider/Add";

import EventPopup from "pages/UI/Popup/Index";

import Settings from "pages/Settings";

import Admins from "pages/Admins/Index";
import AdminCreate from "pages/Admins/Create";
import AdminEdit from "pages/Admins/Edit";

import { useAuth } from "contexts/AuthContext";
import { useEffect } from "react";

const App = () => {
    const { loading, authenticating, admin } = useAuth();
    const navigate = useNavigate();

    let suspense = null;
    if (loading) suspense = "Loading..";
    if (authenticating) suspense = "Authenticating..";

    useEffect(() => {
        if (!loading && !authenticating && !admin) navigate("/auth/login");
    }, [admin, authenticating, loading, navigate]);

    return (
        <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />

            {/* Admin Routes */}
            {admin && (
                <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route
                        path="/categories/create"
                        element={<CategoryCreate />}
                    />
                    <Route
                        path="/categories/:id/edit"
                        element={<CategoryUpdate />}
                    />

                    <Route path="/products" element={<Products />} />
                    <Route
                        path="/products/create"
                        element={<ProductCreate />}
                    />
                    <Route
                        path="/products/:slug/edit"
                        element={<ProductEdit />}
                    />

                    <Route path="/coupons" element={<Coupons />} />

                    <Route path="/orders" element={<Orders />} />
                    <Route path="/orders/create" element={<OrderCreate />} />
                    <Route path="/orders/:id" element={<OrderShow />} />

                    <Route path="/customers" element={<Customers />} />

                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/blogs/create" element={<BlogCreate />} />
                    <Route path="/blogs/:slug/edit" element={<BlogEdit />} />

                    <Route
                        path="/ui/homepage-slider"
                        element={<HomepageSlider />}
                    />
                    <Route
                        path="/ui/homepage-slider/create"
                        element={<AddHomepageSlider />}
                    />

                    <Route path="/ui/popup" element={<EventPopup />} />

                    <Route path="/settings" element={<Settings />} />

                    <Route path="/admins" element={<Admins />} />
                    <Route path="/admins/create" element={<AdminCreate />} />
                    <Route path="/admins/:id/edit" element={<AdminEdit />} />
                </>
            )}
            <Route path="*" element={suspense} />
        </Routes>
    );
};

export default App;
