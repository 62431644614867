import axiosService from "common/axiosService";
import { API_URL } from "common/defines";

export const getCategories = async () =>
    await axiosService.get(API_URL + "/categories");

export const getCategory = async (id) =>
    await axiosService.get(API_URL + "/categories/" + id);

export const deleteCategory = async (id) =>
    await axiosService.delete(API_URL + "/categories/" + id);

export const createCategory = async (values) =>
    await axiosService.post(API_URL + "/categories", values);

export const updateCategory = async (id, values) =>
    await axiosService.post(API_URL + "/categories/" + id, values);
