import * as authApi from "api/auth";
import axiosService from "common/axiosService";
import { PUBLIC_URL } from "common/defines";
import { getToken, setToken } from "common/utils";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [admin, setAdmin] = useState(null);
    const [authenticating, setAuthenticating] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();
    const [loginErrors, setLoginErrors] = useState(null);
    const navigate = useNavigate();

    const getAdmin = useCallback(() => {
        setAuthenticating(true);
        setErrors(false);
        authApi
            .getAdmin()
            .then((response) => {
                setAdmin(response.data.data);
                setErrors(false);
            })
            .catch(() => setErrors(true))
            .finally(() => setAuthenticating(false));
    }, []);

    const login = useCallback((values) => {
        setLoading(true);
        setLoginErrors(null);

        authApi
            .login(values)
            .then((response) => {
                setToken(response.data.token);
                setAdmin(response.data.admin);
                axiosService.setToken(response.data.token);
            })
            .catch((_errors) => setLoginErrors(_errors.response.data))
            .finally(() => setLoading(false));
    }, []);

    const logout = useCallback(() => {
        setLoading(true);
        setErrors(false);
        authApi
            .logout()
            .then(() => {
                setAdmin(null);
                setErrors(false);
                setToken();
                axiosService.setToken();
            })
            .catch(() => setErrors(true))
            .finally(() => setLoading(false));
    }, []);

    const forgotPassword = useCallback((values) => {
        setLoading(true);
        setErrors(false);
        authApi
            .forgotPassword(values)
            .then(() => {
                setErrors(false);
            })
            .catch(() => setErrors(true))
            .finally(() => setLoading(false));
    }, []);

    const resetPassword = useCallback(
        (values) => {
            setLoading(true);
            setErrors(false);
            authApi
                .resetPassword(values)
                .then(() => {
                    setErrors(false);
                    navigate(PUBLIC_URL + "/auth/login");
                })
                .catch(() => setErrors(true))
                .finally(() => setLoading(false));
        },
        [navigate]
    );

    useEffect(() => {
        const token = getToken("access_token");
        axiosService.setToken(token);
        getAdmin();
    }, [getAdmin]);

    const value = {
        admin,
        loading,
        authenticating,
        errors,
        loginErrors,
        login,
        logout,
        forgotPassword,
        resetPassword,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
