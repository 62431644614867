import axiosService from "common/axiosService";
import { API_URL } from "common/defines";

export const getAdmin = async () => await axiosService.get(API_URL + "/admin");

export const login = async (values) =>
    await axiosService.post(API_URL + "/login", values);

export const logout = async () => await axiosService.post(API_URL + "/logout");

export const forgotPassword = async (values) =>
    await axiosService.post(API_URL + "/forgot-password", values);

export const resetPassword = async (values) =>
    await axiosService.post(API_URL + "/reset-password", values);
