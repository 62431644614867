import React, { useEffect, useState } from "react";
import Guest from "components/Layouts/Guest";
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    Card,
    CardContent,
} from "@mui/material";
import { useAuth } from "contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "common/defines";
import ValidationErrors from "components/ValidationErrors";

export default function Login() {
    const { loading, login, admin, loginErrors } = useAuth();
    const navigate = useNavigate();

    const [data, setData] = useState({
        email: "",
        password: "",
        remember: "",
    });

    const onHandleChange = (e) => {
        setData((prevState) => ({
            ...prevState,
            [e.target.name]:
                e.target.name === "remember"
                    ? e.target.checked
                    : e.target.value,
        }));
    };

    const submit = (e) => {
        e.preventDefault();
        login(data);
    };

    useEffect(() => {
        if (!!admin) {
            navigate("/");
        }
    }, [admin, navigate]);

    return (
        <Guest>
            <Card variant="outlined" sx={{ borderRadius: "2rem" }}>
                <CardContent>
                    <Typography variant="h4" textAlign="center">
                        Log In
                    </Typography>
                    <ValidationErrors errors={loginErrors} />
                    <form onSubmit={submit}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            name="email"
                            value={data.email}
                            autoComplete="email"
                            onChange={onHandleChange}
                            fullWidth
                            sx={{ marginY: 2 }}
                        />

                        <TextField
                            label="Password"
                            variant="outlined"
                            type="password"
                            name="password"
                            value={data.password}
                            autoComplete="password"
                            onChange={onHandleChange}
                            fullWidth
                        />

                        <FormControlLabel
                            control={<Checkbox />}
                            label="Remember me"
                            name="remember"
                            value={data.remember}
                            onChange={onHandleChange}
                        />

                        <Button
                            type="submit"
                            size="small"
                            variant="contained"
                            disabled={loading}
                            sx={{ display: "block", width: "100%", marginY: 2 }}
                        >
                            Log in
                        </Button>

                        {/* <Link
                            to={"/auth/forgot-password"}
                            sx={{ display: "block" }}
                        >
                            Forgot your password?
                        </Link> */}
                    </form>
                </CardContent>
            </Card>
        </Guest>
    );
}
