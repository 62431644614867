import axiosService from "common/axiosService";
import { API_URL } from "common/defines";
import { renderQuery } from "common/utils";

export const getOrders = async (query = {}) =>
    await axiosService.get(API_URL + "/orders" + renderQuery(query));

export const getOrderProducts = async () =>
    await axiosService.get(API_URL + "/orders/products");

export const getOrder = async (id) =>
    await axiosService.get(API_URL + "/orders/" + id);

export const updateOrder = async (id, data) =>
    await axiosService.post(API_URL + "/orders/" + id, data);

export const createOrder = async (data) =>
    await axiosService.post(API_URL + "/orders", data);

export const deleteOrder = async (id) =>
    await axiosService.delete(API_URL + "/orders/" + id);
