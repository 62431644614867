import {
    getCategories,
    deleteCategory as deleteCategoryApi,
    createCategory as createCategoryApi,
} from "api/categories";
import { PUBLIC_URL } from "common/defines";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "./AuthContext";
import { useNotification } from "./NotificationContext";

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = ({ children }) => {
    const { admin } = useAuth();
    const [categories, setCategories] = useState();
    const [loading, setLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [createErrors, setCreateErrors] = useState(null);

    const navigate = useNavigate();
    const { notification } = useNotification();

    const handleGetCategories = useCallback(() => {
        setLoading(true);
        setErrors(null);

        getCategories()
            .then((response) => {
                setCategories(response.data.data);
            })
            .catch((errors) => {
                setCategories(null);
                setErrors(errors.response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    const createCategory = useCallback(
        (values) => {
            setCreateLoading(true);
            setCreateErrors(null);

            createCategoryApi(values)
                .then((response) => {
                    setCategories((prev) => [response.data.data, ...prev]);
                    notification.success("Category created successfully");
                    navigate(PUBLIC_URL + "categories");
                })
                .catch((errors) => {
                    setCreateErrors(errors.response.data);
                    notification.failed("Failed to create category");
                })
                .finally(() => setCreateLoading(false));
        },
        [navigate, notification]
    );

    const deleteCategory = useCallback(
        (id) => {
            setDeleteLoading(true);

            deleteCategoryApi(id)
                .then(() => {
                    setCategories((prev) =>
                        prev.filter((_item) => _item.id !== id)
                    );
                    notification.success("Category deleted successfully");
                })
                .catch(() => {
                    notification.failed("Failed to delete category");
                })
                .finally(() => setDeleteLoading(false));
        },
        [notification]
    );

    useEffect(() => {
        if (admin) {
            handleGetCategories();
        }
    }, [admin, handleGetCategories]);

    const value = {
        categories,
        loading,
        createLoading,
        deleteLoading,
        errors,
        createErrors,
        getCategories: handleGetCategories,
        createCategory,
        deleteCategory,
    };

    return (
        <CategoryContext.Provider value={value}>
            {children}
        </CategoryContext.Provider>
    );
};
