export const renderQuery = (query = {}) => {
    return (
        "?" +
        Object.entries(query)
            .map((queryArr) => queryArr.join("="))
            .join("&")
    );
};

export const formatCurrency = (price, locales = "us-US", currency = "USD") => {
    return new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
    }).format(price);
};

export const setToken = (token = null) =>
    localStorage.setItem("access_token", token);

export const getToken = (name) => localStorage.getItem(name);

export const getOrderStatusButton = (status) => {
    const statusMap = {
        Placed: { backgroundColor: "#007bff", color: "#ffffff" },
        Confirmed: { backgroundColor: "#28a745", color: "#ffffff" },
        "Shipping Now": { backgroundColor: "#ffc107", color: "#212529" },
        Delivered: { backgroundColor: "#17a2b8", color: "#ffffff" },
        Completed: { backgroundColor: "#28a745", color: "#ffffff" },
        Canceled: { backgroundColor: "#dc3545", color: "#ffffff" },
    };

    const { backgroundColor, color } = statusMap[status] || {
        backgroundColor: "#ffffff",
        color: "#000000",
    };

    return (
        <div
            style={{
                display: "inline",
                backgroundColor,
                color,
                padding: "0.1rem 0.3rem",
                borderRadius: "1rem",
            }}
        >
            {status}
        </div>
    );
};

export const getPaymentStatusButton = (status) => {
    const statusMap = {
        CREATED: { backgroundColor: "gray", color: "white" },
        PENDING: { backgroundColor: "yellow", color: "black" },
        DUE: { backgroundColor: "yellow", color: "black" },
        COMPLETED: { backgroundColor: "green", color: "white" },
        FAILED: { backgroundColor: "red", color: "white" },
        REFUNDED: { backgroundColor: "orange", color: "black" },
        REVERSED: { backgroundColor: "purple", color: "white" },
        VOIDED: { backgroundColor: "black", color: "white" },
    };

    const { backgroundColor = "white", color = "black" } =
        statusMap[status.toUpperCase()] || {};

    return (
        <div
            style={{
                display: "inline",
                backgroundColor,
                color,
                padding: "0.1rem 0.3rem",
                borderRadius: "1rem",
            }}
        >
            {status}
        </div>
    );
};

export const isObject = (obj) => typeof obj === "object" && obj !== null;
