import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import { Box, Button, Chip, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import DataTable from "components/DataTable/DataTable";
import Dialog from "components/Dialog/Dialog";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { FRONTEND_URL, PUBLIC_URL } from "common/defines";
import { deleteProduct, getProducts } from "api/products";
import { formatCurrency } from "common/utils";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";

const Index = () => {
    const [products, setProducts] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const theme = useTheme();
    const { notification } = useNotification();

    const actions = (
        <Link to={PUBLIC_URL + "/products/create"}>
            <Button variant="contained" size="small" startIcon={<AddIcon />}>
                Add Product
            </Button>
        </Link>
    );

    const headers = [
        "ID",
        "Thumbnail",
        "SKU",
        "Title",
        "Stock",
        "Price",
        "Discount",
        "Status",
        "Category",
        "Reviews",
        "View",
        "Actions",
    ];

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    const handleGetProducts = useCallback((query) => {
        setLoading(true);
        setErrors(null);

        getProducts(query)
            .then((res) => {
                setProducts(res.data);
            })
            .catch((_errors) => {
                setErrors(_errors.response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    const handlePageChange = (curPage) => {
        handleGetProducts({
            page: curPage,
        });
    };

    const handleDelete = () => {
        deleteProduct(dialogContent?.slug)
            .then(() => {
                setProducts((prev) => ({
                    ...prev,
                    data: prev.data.filter(
                        (_item) => _item.slug !== dialogContent.slug
                    ),
                }));
                notification.success("Product deleted successfully");
                handleDialog();
            })
            .catch(() => {
                notification.failed("Failed to delete products");
            });
    };

    useEffect(() => {
        handleGetProducts();
    }, [handleGetProducts]);

    return (
        <Admin sectionTitle="Products" selected={"Products"} actions={actions}>
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={products}
                renderData={(products) => (
                    <DataTable
                        headers={headers}
                        rows={products?.data?.map((product) => [
                            product.id,
                            <img
                                src={product.thumbnail}
                                alt=""
                                style={{ height: "50px" }}
                            />,
                            product.sku,
                            product.title,
                            product.stock,
                            formatCurrency(product.price),
                            formatCurrency(product.discount),
                            product.published ? (
                                <Chip label="Published" color="primary" />
                            ) : (
                                <Chip label="Unpublished" color="error" />
                            ),
                            product.category,
                            product.reviews_count,
                            <a
                                href={FRONTEND_URL + "/product/" + product.slug}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <Button variant="outlined" size="small">
                                    View
                                </Button>
                            </a>,
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.2rem",
                                }}
                            >
                                <Link
                                    to={
                                        PUBLIC_URL +
                                        "/products/" +
                                        product.slug +
                                        "/edit"
                                    }
                                    style={{
                                        color: theme.palette.action.active,
                                    }}
                                >
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                                <IconButton
                                    onClick={() => handleDialog(product)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>,
                        ])}
                        totalPage={products?.meta?.last_page}
                        currentPage={products?.meta?.current_page}
                        handlePageChange={handlePageChange}
                    />
                )}
            />

            <Dialog
                title="Delete Product"
                subTitle="Are you sure you want to delete this product?"
                processing={loading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
