import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import DataTable from "components/DataTable/DataTable";
import * as ordersApi from "api/orders";
import {
    formatCurrency,
    getOrderStatusButton,
    getPaymentStatusButton,
} from "common/utils";
import { Link } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import { PUBLIC_URL } from "common/defines";
import FetchDataWrapper from "components/FetchDataWrapper";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import moment from "moment";
import Dialog from "components/Dialog/Dialog";
import { useNotification } from "contexts/NotificationContext";

const Index = () => {
    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();

    const { notification } = useNotification();

    const handleDialog = (item = null) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
    };

    // prepare categories for datatable
    const headers = [
        "ID",
        "Date",
        "Status",
        "Order Amount",
        "Item Qty.",
        "Customer",
        "Payment Status",
        "View",
        "Action",
    ];

    const rows = orders?.data?.map((order) => [
        order.id,
        moment(order.created_at).format("MM-DD-YYYY hh:mm A"),
        getOrderStatusButton(order?.status),
        formatCurrency(order.grand_total),
        order.items_count,
        `${order.customer.first_name} ${order.customer.last_name}`,
        getPaymentStatusButton(order.payment_status),
        <Link to={PUBLIC_URL + "/orders/" + order.id}>
            <Button variant="outlined" size="small">
                View
            </Button>
        </Link>,
        <IconButton onClick={() => handleDialog(order)}>
            <DeleteIcon />
        </IconButton>,
    ]);

    const handleGetOrders = useCallback((query) => {
        setLoading(true);
        setErrors(null);

        ordersApi
            .getOrders(query)
            .then((res) => {
                setOrders(res.data);
            })
            .catch((_errros) => {
                setErrors(_errros.response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    const handlePageChange = (curPage) => {
        handleGetOrders({
            page: curPage,
        });
    };

    const handleDelete = () => {
        ordersApi
            .deleteOrder(dialogContent?.id)
            .then(() => {
                setOrders((prev) => ({
                    ...prev,
                    data: prev.data.filter(
                        (_item) => _item.id !== dialogContent.id
                    ),
                }));
                notification.success("Order deleted successfully");
                handleDialog();
            })
            .catch(() => {
                notification.failed("Failed to delete order");
            });
    };

    const actions = (
        <Link to={PUBLIC_URL + "/orders/create"}>
            <Button variant="contained" size="small" startIcon={<AddIcon />}>
                Add Order
            </Button>
        </Link>
    );

    useEffect(() => {
        handleGetOrders();
    }, [handleGetOrders]);

    return (
        <Admin sectionTitle="Orders" selected={"Orders"} actions={actions}>
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={orders}
                renderData={(data) => (
                    <DataTable
                        headers={headers}
                        rows={rows}
                        totalPage={orders?.meta?.last_page}
                        currentPage={orders?.meta?.current_page}
                        handlePageChange={handlePageChange}
                    />
                )}
            />
            <Dialog
                title="Delete Order"
                subTitle="Are you sure you want to delete this order?"
                processing={loading}
                show={showDialog}
                handleDialog={handleDialog}
                handleConfirm={handleDelete}
            />
        </Admin>
    );
};

export default Index;
