import React, { useCallback, useEffect, useState } from "react";
import Admin from "components/Layouts/Admin";
import * as ordersApi from "api/orders";
import { useParams } from "react-router-dom";
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import TabPanel from "components/TabPanel";
import DataTable from "components/DataTable/DataTable";
import { FRONTEND_URL } from "common/defines";
import { formatCurrency, getOrderStatusButton } from "common/utils";
import Dialog from "components/Dialog/Dialog";
import FetchDataWrapper from "components/FetchDataWrapper";
import { useNotification } from "contexts/NotificationContext";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Show = () => {
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [value, setValue] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState();
    const { notification } = useNotification();
    const [trackingLink, setTrackingLink] = useState();
    const [customInvoiceDate, setCustomInvoiceDate] = useState();
    const [invoiceModal, setInvoiceModal] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { id } = useParams();

    const handleGetOrder = useCallback(() => {
        setLoading(true);
        setErrors(null);

        ordersApi
            .getOrder(id)
            .then((res) => {
                setOrder(res.data.data);
            })
            .catch((_errros) => {
                setErrors(_errros.response.data);
            })
            .finally(() => setLoading(false));
    }, [id]);

    const handleDialog = (item) => {
        setShowDialog((prev) => !prev);
        setDialogContent((prev) => (prev ? null : item));
        setTrackingLink(null);
    };

    const handleChangeOrderStatus = () => {
        if (dialogContent === "Shipping Now") {
            if (!trackingLink) {
                notification.failed("Please enter tracking link");
                return;
            }
        }

        ordersApi
            .updateOrder(order.id, {
                status: dialogContent,
                tracking_number: trackingLink,
            })
            .then((res) => {
                setOrder(res.data.data);
                notification.success("Order status changed");
            })
            .catch((_errros) => {
                notification.failed("Failed to change order status");
            })
            .finally(() => {
                handleDialog();
            });
    };

    const handleDownloadInvoice = () => {
        setInvoiceModal(false);
        const doc = new jsPDF();

        autoTable(doc, {
            body: [
                [
                    {
                        content: "YourPaints",
                        styles: {
                            halign: "left",
                            fontSize: 20,
                            textColor: "#000000",
                        },
                    },
                    {
                        content: "Invoice",
                        styles: {
                            halign: "right",
                            fontSize: 20,
                            textColor: "#000000",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content:
                            "\nDate: " +
                            moment(
                                customInvoiceDate ?? order.created_at
                            ).format("MM-DD-YYYY") +
                            "\nInvoice number: INVYP00" +
                            order.id,
                        styles: {
                            halign: "right",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content:
                            "Billed to:" +
                            "\n" +
                            order.billing_details.first_name +
                            order.billing_details.last_name +
                            "\n" +
                            order.billing_details.phone +
                            "\n" +
                            order.billing_details.email,
                        styles: {
                            halign: "left",
                        },
                    },
                    {
                        content:
                            "Shipping address:" +
                            "\n" +
                            order.billing_details.street_address +
                            "\n" +
                            order.billing_details.postcode +
                            ", " +
                            order.billing_details.city +
                            ", " +
                            order.billing_details.province +
                            "\n" +
                            order.billing_details.country,
                        styles: {
                            halign: "left",
                        },
                    },
                    {
                        content:
                            "From:" +
                            "\nYourPaints" +
                            "\n941 Roundelay Drive" +
                            "\nL1j 7S4, Oshawa, ON" +
                            "\nyourpaints@outlook.com",
                        styles: {
                            halign: "right",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            head: [["Product", "Hrs/Qty", "Rate/Price", "Adjust", "Sub Total"]],
            body: order.items.map((item) => [
                item.product.title,
                item.quantity,
                "$" + item.price,
                "0%",
                "$" + item.total_amount,
            ]),
            theme: "striped",
            headStyles: {
                fillColor: "#343a40",
            },
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: "Sub Total:",
                        styles: {
                            halign: "right",
                        },
                    },
                    {
                        content: "$" + order.subtotal,
                        styles: {
                            halign: "right",
                        },
                    },
                ],
                [
                    {
                        content: "HST:",
                        styles: {
                            halign: "right",
                        },
                    },
                    {
                        content: "$" + order.tax,
                        styles: {
                            halign: "right",
                        },
                    },
                ],
                [
                    {
                        content: "Shipping Charge:",
                        styles: {
                            halign: "right",
                        },
                    },
                    {
                        content: "$" + order.shipping_charge,
                        styles: {
                            halign: "right",
                        },
                    },
                ],
                ...(order.coupon_title
                    ? [
                          [
                              {
                                  content: "Coupon:",
                                  styles: {
                                      halign: "right",
                                  },
                              },
                              {
                                  content:
                                      "-$" +
                                      order.coupon_value +
                                      " (" +
                                      order.coupon_title +
                                      ")",
                                  styles: {
                                      halign: "right",
                                  },
                              },
                          ],
                      ]
                    : []),
                [
                    {
                        content: "Total Amount:",
                        styles: {
                            halign: "right",
                        },
                    },
                    {
                        content: "$" + order.grand_total,
                        styles: {
                            halign: "right",
                        },
                    },
                ],
                [
                    {
                        content: "Total Due:",
                        styles: {
                            halign: "right",
                            fontStyle: "bold",
                        },
                    },
                    {
                        content:
                            order.payment.status === "DUE"
                                ? "$" + order.payment.amount
                                : "$0",
                        styles: {
                            halign: "right",
                            fontStyle: "bold",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content:
                            "Thanks for choosing YourPaints | yourpaints@outlook.com | www.yourpaints.ca",
                        styles: {
                            halign: "center",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        return doc.save("invoice");
    };

    useEffect(() => {
        handleGetOrder();
    }, [handleGetOrder]);

    return (
        <Admin
            sectionTitle={
                <div>
                    <span>Order # {order?.id}</span>
                    <span style={{ marginLeft: "0.5rem" }}>
                        {getOrderStatusButton(order?.status)}
                    </span>
                </div>
            }
            actions={
                order ? (
                    <>
                        <FormControl>
                            <Select
                                size="small"
                                value={order.status}
                                onChange={(e) => handleDialog(e.target.value)}
                            >
                                <MenuItem value={"Placed"}>Placed</MenuItem>
                                <MenuItem value={"Confirmed"}>
                                    Confirmed
                                </MenuItem>
                                <MenuItem value={"Shipping Now"}>
                                    Shipping Now
                                </MenuItem>
                                <MenuItem value={"Delivered"}>
                                    Delivered
                                </MenuItem>
                                <MenuItem value={"Completed"}>
                                    Completed
                                </MenuItem>
                                <MenuItem value={"Canceled"}>Canceled</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            onClick={() => setInvoiceModal(true)}
                            sx={{ marginLeft: "1rem" }}
                        >
                            Download Invoice
                        </Button>
                    </>
                ) : null
            }
            selected={"Orders"}
        >
            <FetchDataWrapper
                loading={loading}
                errors={errors}
                data={order}
                renderData={(data) => (
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Order Items" />
                                <Tab label="Invoice" />
                                <Tab label="Order Shipment" />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DataTable
                                        headers={[
                                            "ID",
                                            "SKU",
                                            "Title",
                                            "Quantity",
                                            "Price",
                                            "Total",
                                        ]}
                                        rows={data.items?.map((_item) => [
                                            _item.product.id,
                                            _item.product.sku,
                                            <a
                                                href={
                                                    FRONTEND_URL +
                                                    "/product/" +
                                                    _item.product.slug
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {_item.product.title}
                                            </a>,
                                            _item.quantity,
                                            formatCurrency(_item.price),
                                            formatCurrency(_item.total_amount),
                                        ])}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={4}
                                    sx={{
                                        marginTop: "2rem",
                                        marginLeft: "auto",
                                    }}
                                >
                                    <Divider />
                                    <DataTable
                                        headers={[]}
                                        rows={[
                                            [
                                                "Subtotal",
                                                formatCurrency(data.subtotal),
                                            ],
                                            ["HST", formatCurrency(data.tax)],
                                            [
                                                "Shipping Charge",
                                                formatCurrency(
                                                    data.shipping_charge
                                                ),
                                            ],
                                            data.coupon_title
                                                ? [
                                                      "Coupon",
                                                      `-${formatCurrency(
                                                          data.coupon_value
                                                      )} (${
                                                          data.coupon_title
                                                      })`,
                                                  ]
                                                : [],
                                            [
                                                "Grand Total",
                                                formatCurrency(
                                                    data.grand_total
                                                ),
                                            ],
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <DataTable
                                headers={[
                                    "Paid By",
                                    "Status",
                                    "payment  ID",
                                    "Currency",
                                    "Amount",
                                    "Date",
                                ]}
                                rows={[
                                    [
                                        data.payment?.method,
                                        data.payment.status?.toUpperCase(),
                                        data.payment.payment_id,
                                        data.payment.currency?.toUpperCase(),
                                        formatCurrency(data.payment?.amount),
                                        moment(data.payment?.created_at).format(
                                            "MM-DD-YYYY hh:mm A"
                                        ),
                                    ],
                                ]}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <DataTable
                                headers={[]}
                                rows={[
                                    ...Object.entries(
                                        data.billing_details
                                    )?.map((_item) => [
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {_item[0]?.replace("_", " ")}
                                        </span>,
                                        <span>{_item[1]}</span>,
                                    ]),
                                    ...(order.status === "Shipping Now"
                                        ? [
                                              [
                                                  "Tracking Number",
                                                  data.tracking_number,
                                              ],
                                          ]
                                        : []),
                                ]}
                            />
                        </TabPanel>
                    </Box>
                )}
            />
            <Dialog
                show={showDialog}
                processing={loading}
                title="Change order status"
                subTitle="Are you sure you want to change the order status?"
                body={
                    dialogContent === "Shipping Now" ? (
                        <>
                            <TextField
                                type="text"
                                required
                                fullWidth
                                label="Tracking Link"
                                value={trackingLink}
                                onChange={(event) =>
                                    setTrackingLink(event.target.value)
                                }
                                sx={{ marginTop: "1rem" }}
                            />
                        </>
                    ) : null
                }
                handleDialog={handleDialog}
                handleConfirm={handleChangeOrderStatus}
            />

            {order && (
                <Dialog
                    show={invoiceModal}
                    title="Invoice date"
                    body={
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            dateFormats={{
                                fullDateTime: "yy-mm-dd",
                            }}
                        >
                            <DatePicker
                                defaultValue={new Date(order.created_at)}
                                onChange={(newValue) =>
                                    setCustomInvoiceDate(newValue)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                )}
                                sx={{
                                    marginTop: "1rem",
                                    width: "100%",
                                }}
                            />
                        </LocalizationProvider>
                    }
                    handleDialog={() => setInvoiceModal(false)}
                    handleConfirm={handleDownloadInvoice}
                />
            )}
        </Admin>
    );
};

export default Show;
