import axios from "axios";

class AxiosService {
    constructor() {
        const instance = axios.create({
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                Accept: "application/json",
            },
            withCredentials: true,
        });
        instance.interceptors.response.use(
            this.handleSuccess,
            this.handleError
        );
        this.instance = instance;
    }

    setToken(token = null) {
        this.instance.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${token}`;
    }

    handleSuccess(response) {
        return response;
    }

    handleError(error) {
        return Promise.reject(error);
    }

    get(url) {
        return this.instance.get(url);
    }

    post(url, body) {
        return this.instance.post(url, body);
    }

    patch(url, body) {
        return this.instance.patch(url, body);
    }

    put(url, body) {
        return this.instance.put(url, body);
    }

    delete(url) {
        return this.instance.delete(url);
    }
}

export default new AxiosService();
